.parallaxbg-section {
    .row {
        z-index: 10;
    }

    .content-wrap {
        border-radius: 3px;

        h3 {
            margin-bottom: pxToRem(20px);
        }

        p {
            line-height: pxToRem(22px);
        }
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media only screen and (max-width: 992px) {
        h3 {
            font-size: pxToRem(22px);
        }

        p {
            font-size: pxToRem(12px);
            line-height: pxToRem(16px);
        }

        .content-wrap {
            padding-top: pxToRem(25px);
            padding-bottom: pxToRem(25px);
            padding-left: pxToRem(65px);
            padding-right: pxToRem(65px);
        }

        background-position: center;
    }
}
