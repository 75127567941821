.special-offer {
    position: relative;
    z-index: 10;

    .container {
        transition: all 0.25s ease-in-out;
        border-radius: 3px;

        .logo-image {
            padding: pxToRem(40px);
            border-bottom: 1px solid white;

            img {
                max-width: 185px;
            }
        }

        .content-wrap {
            padding: pxToRem(40px);
            display: flex;
            flex-direction: column;
            position: relative;
            transition: all 0.25s ease-in-out;

            a {
                color: white;
            }
        }

        .borders {
            border-radius: 3px;
        }

        &:hover {
            background-color: $color-red-darker !important;
        }
    }

    @media only screen and (max-width: 992px) {
        h2 {
            font-size: pxToRem(21px);
            margin-bottom: pxToRem(10px);
        }

        p {
            font-size: pxToRem(12px);
            line-height: pxToRem(16px);
        }

        .logo-image {
            padding-top: pxToRem(32px) !important;
            padding-left: 0 !important;
            padding-bottom: pxToRem(32px) !important;
            padding-right: pxToRem(40px) !important;
        }

        .content-wrap {
            padding-top: pxToRem(20px) !important;
            padding-left: 0 !important;
            padding-bottom: pxToRem(25px) !important;
            padding-right: pxToRem(40px) !important;
        }

        .right-image-wrap {
            img {
                position: absolute;
                right: 16%;
                top: 0;
                min-width: 370px;
                max-width: 300px;
                height: auto;
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .right-image-wrap {
            img {
                right: 11%;
            }
        }
    }

    @media only screen and (max-width: 812px) {
        .right-image-wrap {
            img {
                right: 8%;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .container {
            .content-wrap {
                @media only screen and (max-width: 768px) {
                    max-width: pxToRem(250px);
                }
            }
        }

        .right-image-wrap {
            img {
                position: absolute;
                right: 15%;
                top: 0;
                min-width: 255px;
                max-width: 255px;
                height: auto;
            }
        }
    }

    @media only screen and (max-width: 655px) {
        .right-image-wrap {
            img {
                right: 12%;
            }
        }
    }

    @media only screen and (max-width: 580px) {
        .right-image-wrap {
            img {
                right: 7%;
            }
        }
    }

    @media only screen and (max-width: 510px) {
        .right-image-wrap {
            img {
                right: 4%;
            }
        }
    }

    @media only screen and (max-width: 470px) {
        align-items: center;
        justify-content: center;
        display: flex;

        .right-image-wrap {
            display: none !important;
        }

        .logo-image {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 0 !important;
            text-align: center;
        }

        .content-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin: auto;
            padding-right: 0 !important;
        }
    }
}
