.products-variants {
    .single-variant {
        background-color: $color-gray-lightest;
        border-radius: 3px;
        padding: pxToRem(15px);
        margin: pxToRem(10px) auto;
        transition: all 0.3s ease-in-out;

        &:hover {
            background-color: $color-gray-lightest3;
            -webkit-box-shadow: 0px 0px 12px 2px rgba(#000000, 0.1);
            box-shadow: 0px 0px 12px 2px rgba(#000000, 0.1);
        }

        .image-wrap {
            min-height: pxToRem(85px);
            max-height: pxToRem(85px);
            width: 100%;
            background: white;
            border: 1px solid $color-gray-lightest;
            border-radius: 3px;
            display: flex;
            align-items: center;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                display: block;
            }

            @media only screen and (max-width: 768px) {
                height: pxToRem(85px);
                width: pxToRem(85px);
                margin: auto;
            }
        }

        .product-name {
            @media only screen and (max-width: 768px) {
                width: 100%;
                margin: 1rem auto;
                text-align: center;
            }
        }

        .product-availability {
            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .icon {
                max-width: pxToRem(15px);
                min-width: pxToRem(15px);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: pxToRem(10px);
            }

            a {
                &:hover {
                    color: $color-dark;
                }
            }
        }

        .variant-price-wrap {
            span {
                font-size: pxToRem(18px);
                font-weight: bold;
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
                margin: 1rem auto;
                text-align: center;
            }
        }

        .quantity {
            display: flex;
            min-height: pxToRem(40px);

            input {
                background: white;
                color: $color-gray;
                min-width: pxToRem(70px);
                border: none;
                text-align: center;
                font-weight: bold;
                font-size: pxToRem(16px);
                border: 1px solid $color-gray-lightest;

                &:focus {
                    outline: none;
                }
            }

            .quantity-value-buttons {
                background: $color-gray-lightest2;
                display: flex;
                flex-direction: column;
                min-width: pxToRem(28px);
                align-items: center;
                justify-content: center;
                border: 1px solid $color-gray-lightest;

                &.minus {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                }

                &.plus {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                }

                a {
                    color: $color-red-categories-hover;
                    display: flex;
                    text-align: center;
                    text-decoration: none;
                    justify-content: center;
                    font-weight: bold;
                    font-size: pxToRem(15px);
                    width: 100%;
                }
            }

            @media only screen and (max-width: 768px) {
                width: 100%;
                margin-bottom: 1rem;
                text-align: center;
                justify-content: center;
            }
        }

        @media only screen and (max-width: 768px) {
            padding: 0;
            background: inherit;
            margin: 0;
        }
    }

    &.cart-products-summary {
        .single-variant {
            background: white;
            border: 1px solid #e8e4e4;
            position: relative;

            .remove-btn {
                position: absolute;
                right: 5px;
                top: 5px;
                cursor: pointer;

                img {
                    max-width: 15px;
                    min-width: 15px;
                    display: block;
                    width: 100%;
                    transition: all 0.3s ease-in-out;
                    opacity: 0.55;
                }

                &:hover {
                    img {
                        opacity: 1;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                padding: 0;
                background: inherit;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .mobile-row {
            align-items: center;
            padding: pxToRem(15px);
            background-color: #f5f4f4;
            margin: pxToRem(9px) pxToRem(15px);

            .half-col {
                flex: 0 0 auto;
                width: 50%;

                &.left-col {
                    .row-wrap {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .content-wrap {
                            margin-left: pxToRem(15px);

                            .product-availability {
                                span {
                                    align-items: center;
                                    justify-content: flex-start;
                                }
                            }

                            .variant-title {
                                margin-bottom: pxToRem(10px);
                            }
                        }

                        .image-wrap {
                            max-width: pxToRem(105px);
                            min-height: pxToRem(105px);
                            max-height: pxToRem(105px);
                            min-width: pxToRem(105px);

                            img {
                                max-width: pxToRem(100px);
                                min-width: pxToRem(100px);
                            }
                        }
                    }
                }

                &.right-col {
                    display: flex;
                    flex-direction: row;

                    .row-wrap {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                    }

                    .bottom-row {
                        display: flex;
                        margin-top: pxToRem(15px);
                        flex-direction: row;

                        .add-to-card-btn {
                            a {
                                max-width: 150px;
                                min-width: 150px;
                            }
                        }
                    }

                    .quantity {
                        margin-bottom: 0;
                        margin-right: pxToRem(10px);
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .mobile-row {
            flex-direction: column;

            .half-col {
                width: 100%;

                &.left-col {
                    .row-wrap {
                        justify-content: center;

                        .image-wrap {
                            max-width: pxToRem(126px);
                            min-height: pxToRem(126px);
                            max-height: pxToRem(126px);
                            min-width: pxToRem(126px);
                            margin: inherit !important;

                            img {
                                max-width: pxToRem(124px);
                                min-width: pxToRem(124px);
                            }
                        }

                        .content-wrap {
                            text-align: left;

                            .variant-title {
                                text-align: left;
                            }

                            .product-availability {
                                a {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }

                &.right-col {
                    .variant-price-wrap {
                        margin-bottom: 0;
                    }

                    .quantity {
                        margin-bottom: 0;
                        margin-right: pxToRem(10px);
                    }
                }
            }
        }
    }
}
