.help-blocks {
    .single-help-block {
        .content-wrap {
            min-height: pxToRem(550px);
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: center;

            .bottom-wave {
                max-width: pxToRem(395px);
                width: 100%;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            .fullbg-wrap {
                width: pxToRem(395px);

                &.bg-blue-darker3 {
                    background: transparent;

                    .content {
                        background-color: $color-blue-darker3;
                    }
                }

                &.bg-blue-darker {
                    background: transparent;

                    .content {
                        background-color: $color-blue-darker;
                    }
                }

                &.bg-red-categories-hover {
                    background: transparent;

                    .content {
                        background-color: $color-red-categories-hover;
                    }
                }

                &.bg-green-lighter {
                    background: transparent;

                    .content {
                        background-color: $color-green-lighter;
                    }
                }

                &.bg-gray-darker {
                    background: transparent;

                    .content {
                        background-color: $color-gray-darker;
                    }
                }

                &.bg-orange {
                    background: transparent;

                    .content {
                        background-color: $color-orange;
                    }
                }

                &.bg-blue-dark {
                    background: transparent;

                    .content {
                        background-color: $color-blue-dark;
                    }
                }
            }
        }

        &.block-transport {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/single-help-block-doprava.jpg');
                background-size: cover;
            }
        }

        &.block-priprava {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/single-help-block-priprava.jpg');
                background-size: cover;
            }
        }

        &.block-reklamace {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/help_block_bg_reklamace_block.jpg');
                background-size: cover;
            }
        }

        &.block-our-shops {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/singe-block-our-shops.jpg');
                background-size: cover;
            }
        }

        &.block-barvy {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/single-help-block-barvy.jpg');
                background-size: cover;
            }
        }

        &.block-pomucky {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/single-help-block-pomucky.jpg');
                background-size: cover;
            }
        }

        &.block-uklid {
            .content-wrap {
                background-image: url('#{$relative-path-to-images}/img/single-help-block-uklid.jpg');
                background-size: cover;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .single-help-block {
            .content-wrap {
                .fullbg-wrap,
                .bottom-wave {
                    width: 285px;
                }

                h3 {
                    font-size: pxToRem(21px);
                }

                .paragraph-title {
                    font-size: pxToRem(12px);
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .single-help-block {
            margin-bottom: pxToRem(15px);

            .content-wrap {
                .fullbg-wrap,
                .bottom-wave {
                    width: 350px;
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .single-help-block {
            .content-wrap {
                .fullbg-wrap,
                .bottom-wave {
                    width: 300px;
                }
            }
        }
    }
}
