h1 {
    font-size: pxToRem(31px);
    font-weight: bold;
    color: $color-dark;
}

h2 {
    font-weight: bold;
    font-size: pxToRem(30px);
    line-height: (35 / 30);
    margin: 0 0 pxToRem(30px);
    padding-top: 16px;
    color: $color-dark;
}

h3 {
    color: $color-dark;
    font-weight: 500;
    font-size: pxToRem(22px);
}

p,
p.paragraph-font {
    font-size: pxToRem(14px);
    font-weight: 300;
    line-height: pxToRem(22px);
}

.paragraph-smaller {
    font-size: pxToRem(12px);
}

.paragraph-normal-bold {
    font-size: pxToRem(14px);
    font-weight: 800;
}

.lh-20 {
    line-height: pxToRem(20px);
}

.lh-26 {
    line-height: pxToRem(26px) !important;
}

.paragraph-medium {
    font-size: pxToRem(16px);
    font-weight: 500;

    p {
        font-size: pxToRem(16px);
        font-weight: 500;
    }
}

.text-extrabold {
    font-weight: 900 !important;
}

.paragraph-bigger {
    font-size: pxToRem(20px);
    font-weight: 500;
    line-height: pxToRem(28px);
}

.title-account {
    font-size: pxToRem(28px);
    font-weight: bold;
    color: $color-dark;

    @media only screen and (max-width: 1200px) {
        font-size: pxToRem(22px);
        text-align: center;
    }
}

.single-help-block-title {
    font-size: pxToRem(28px);
    font-weight: bold;
}

.content-width-medium {
    max-width: pxToRem(555px);
}

.content-width-smaller {
    max-width: pxToRem(275px);
}

.title-category-description {
    font-size: pxToRem(16px);
    font-weight: 500;
    text-transform: uppercase;
}

.list-without-padding {
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside;

    li {
        list-style-position: outside;
        margin-left: 1em;
    }
}

.title-promo {
    font-size: pxToRem(22px);
    font-weight: 800;
    text-transform: uppercase;
}

.title-promo-bigger {
    font-size: pxToRem(33px);
    font-weight: 800;

    @media only screen and (max-width: 1200px) {
        font-size: pxToRem(25px);
    }
}

.title-parallax {
    font-size: pxToRem(33px);
    font-weight: 400;
}

.page-subtitle-bigger {
    font-size: pxToRem(22px);
    font-weight: 600;
    color: #000000;
}

.title-footer {
    font-size: pxToRem(20px);
    font-weight: 300;
}

.title-main-page {
    font-size: pxToRem(36px);
    font-weight: bold;
}

.title-subtitle-page {
    font-size: pxToRem(26px);
    font-weight: bold;
}

.page-title-padding {
    padding: pxToRem(40px) 0;
}

.page-subtitle {
    font-size: pxToRem(18px);
    font-weight: 600;
}

.variant-title {
    font-size: pxToRem(18px);
    font-weight: 500;

    a {
        color: $color-dark;
    }
}

.link-with-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img,
    svg {
        max-width: 12px;
        height: auto;
        display: block;
        margin-left: 0.5rem;
    }
}
