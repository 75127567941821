.contact-wrap {
    .contact-first-row {
        .content-with-bg {
            background-color: $color-alert-primary;
            border: 1px solid $color-blue-light;
            border-radius: 3px;
        }
    }

    .form-wrapper {
        background-color: $color-alert-primary;
        border: 1px solid $color-blue-light;
        border-radius: 3px;
        box-shadow: 0px 6px 12px 0px rgba(244, 250, 250, 1);

        label {
            color: $color-blue-darker2;
            font-weight: bold;
            font-size: pxToRem(14px);
        }

        .form-control {
            min-height: pxToRem(50px);
            color: $color-blue-darker2;
            font-weight: 400;
            border: 2px solid $color-blue-lighter;
            background-color: white;
            font-size: pxToRem(14px);

            &:focus {
                box-shadow: none !important;
            }
        }

        .form-check-inline {
            padding-left: pxToRem(45px);
        }

        .form-check-input:checked {
            background-color: $color-blue-darker2;
            border-color: $color-blue-darker2;
        }

        input[type='checkbox'] {
            width: 23px !important;
            height: 23px !important;
            cursor: pointer !important;
            border-radius: 3px !important;
            outline: 2px solid $color-blue-lighter;
            border: none !important;
            margin-left: -1.425em;

            &:focus {
                box-shadow: none !important;
            }
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }

        input[type='text'],
        input[type='password'],
        input[type='number'],
        input[type='email'],
        input[type='tel'],
        textarea {
            color: $color-blue-darker2;
            font-weight: 400;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
            }

            &::placeholder {
                color: $color-blue-darker2;
                font-weight: 300;
            }
        }

        @media only screen and (max-width: 992px) {
            padding: pxToRem(15px) !important;
        }
    }
}
