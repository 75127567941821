.small-categories {
    &__category {
        margin-top: pxToRem(8px);
        margin-bottom: pxToRem(8px);

        a {
            text-decoration: none;
        }

        &__wrapper {
            display: flex;
            background: #fff;
            border-radius: pxToRem(3px);
            border: 1px solid #f0e7e7;
            height: 100%;
        }

        &__divider {
            width: 1px;
            height: auto;
            margin: pxToRem(5px);
            background: transparent;
            border-left: 1px solid #f0e7e7;

            @media only screen and (max-width: 769px) {
                margin: pxToRem(5px) pxToRem(30px);
            }
        }

        &__categoryimage {
            display: flex;
            //margin-top: pxToRem(-11px);

            img {
                width: 100%;
                height: 100%;
                max-width: pxToRem(88px);
                max-height: pxToRem(55px);
                min-width: pxToRem(88px);
                min-height: pxToRem(55px);
                object-fit: cover;

                @media only screen and (max-width: 769px) {
                    min-width: pxToRem(108px);
                    max-width: pxToRem(108px);
                }
            }
        }

        &__categorytitle {
            padding: pxToRem(15px);
            padding-right: pxToRem(20px);
            font-size: pxToRem(14px);
            font-weight: 500;
            padding-top: 0;
            padding-bottom: 0;
            color: #230d12;
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: 992px) {
                padding-right: pxToRem(5px);
                font-size: pxToRem(11px);

                @media only screen and (max-width: 768px) {
                    font-size: pxToRem(14px);
                }
            }
        }
    }
}
