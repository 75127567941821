:root{
    --cc-btn-primary-bg: #f20001;
    --cc-btn-primary-hover-bg: #da1819;

    --cc-btn-secondary-bg: #f20001;
    --cc-btn-secondary-hover-bg: #da1819;
    --cc-btn-secondary-text: white;
}

.c-tgl {
    display: none !important;
}

#c-ttl, #s-ttl, .b-tl {
    color: #f20001
}

#c-txt {
    a {
        color: #f20001;

        &:hover {
            color: #da1819;
        }
    }
}