.cart-widget {
    a {
        text-decoration: none;
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }
}
