.scroll-to-top {
    &__btn {
        background-color: $color-secondary;
        border: none;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        font-size: 16px;
        line-height: 48px;
        width: 48px;
        height: 48px;
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 100;
        opacity: 0;
        transform: translateY(100px);
        transition: all .5s ease;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    &__arrow {
        border-color: transparent transparent white;
        border-style: solid;
        border-width: 13px;
        height: 0;
        width: 0;
        margin-top: 2px;
    }

    &__show-btn {
        opacity: 1;
        transform: translateY(0)
    }
}