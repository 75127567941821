.store-block {
    .image-wrap {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border: 1px solid $color-gray-lightest;

        img {
            width: 100%;
            height: auto;
            display: block;
            object-fit: cover;
            min-height: 280px;
            max-height: 280px;
        }
    }

    .content-wrap {
        border-radius: 3px;
        border: 1px solid $color-blue-light;
        box-shadow: rgba($color-blue-lightest, 0.1) 0px 8px 24px;

        .adresses-wrap {
            .contact-row {
                display: flex;
                flex-direction: row;
                margin-bottom: pxToRem(15px);

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 15px;
                        min-width: 15px;
                        height: auto;
                        margin-right: pxToRem(20px);
                    }
                }

                a {
                    &.text-dark {
                        &:hover {
                            color: $color-blue-darker2;
                        }
                    }
                }
            }
        }

        .shop-detail-addresses {
            .contact-row {
                display: flex;
                flex-direction: row;
                margin-bottom: pxToRem(20px);
                align-items: flex-start;

                .icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 15px;
                        min-width: 15px;
                        height: auto;
                        margin-right: pxToRem(20px);
                    }
                }

                /* span {
                    line-height: pxToRem(24px);
                } */

                a {
                    &.text-dark {
                        &:hover {
                            color: $color-blue-darker2;
                        }
                    }
                }
            }
        }
    }

    &.with-image {
        .content-wrap {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
    }

    @media only screen and (max-width: 992px) {
        h2 {
            font-size: pxToRem(21px);
        }
    }
}
