.concurrency-block {
    .content-wrap {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .image-col {
        img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 3px;
        }
    }

    @media only screen and (max-width: 992px) {
        h3 {
            font-size: pxToRem(22px);
        }

        ul {
            li {
                font-size: pxToRem(12px);
            }
        }

        .content-wrap {
            padding-top: pxToRem(25px);
            padding-bottom: pxToRem(25px);
            padding-left: pxToRem(35px);
            padding-right: pxToRem(35px);
        }

        margin-bottom: pxToRem(40px);
    }
}
