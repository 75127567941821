.info-bar-wrapper {
    position: relative;
    z-index: 10;
}

.info-bar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 34px;
    line-height: pxToRem(30px);
    padding: 2px 0;
    display: flex;
    justify-content: center;
    font-size: pxToRem(13px);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;

    &--open {
        visibility: visible;
        opacity: 1;
    }

    &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 2px;
        right: 25px;
        cursor: pointer;
        z-index: 11;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            font-weight: 700;
            line-height: pxToRem(26px);
            font-size: pxToRem(13px);
        }
    }

    > p {
        margin: 0;
    }
}

@keyframes slide {
    from {height: 0;}
    to {height: 34px;}
}