.newsletter-section {
    @media only screen and (max-width: 992px) {
        .title {
            font-size: pxToRem(18px);
            line-height: pxToRem(24px);
        }

        .image-wrap {
            margin-top: -10px !important;
        }
    }

    @media only screen and (max-width: 768px) {
        .mobile-block {
            .image-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: pxToRem(-25px);

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    min-height: pxToRem(180px);
                }
            }

            .title {
                margin-bottom: pxToRem(10px);
            }

            .content-wrap {
                margin-right: pxToRem(35px);
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .mobile-block {
            .content-wrap {
                margin-right: 0;
            }
        }
    }
}
