.cart-icons-row {
    .row {
        box-shadow: inset 5px 5px 15px 5px rgba(0, 0, 0, 0.09);
        padding: pxToRem(25px) 0;
    }

    .col-wrap {
        padding: pxToRem(15px);

        .icon-wrap {
            img {
                max-width: 35px;
                min-width: 35px;
                height: auto;
                display: block;
            }
        }

        h5 {
            color: $color-blue-darker;
            font-weight: bold;
            font-size: pxToRem(18px);
            margin: pxToRem(15px) auto;
        }

        p {
            color: $color-dark;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}
