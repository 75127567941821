#categoryactivefilters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .chosen-filter {
        cursor: pointer;
        color: $color-red-categories-hover;
        padding: 3px 10px;
        background: white;
        border: 1px solid $color-brow-light;
        border-radius: pxToRem(3px);
        margin-top: pxToRem(10px);

        &::after {
            background-image: url('#{$relative-path-to-images}/img/filter_close.svg');
            width: 10px;
            height: 10px;
            content: '';
            margin-left: pxToRem(5px);
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
        }
    }

    .chosen-filter:not(:last-child) {
        margin-right: pxToRem(10px);
    }
}
