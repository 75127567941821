.orders-wrap {
    background-color: $color-alert-primary;
    border: 1px solid $color-blue-light;
    border-radius: 3px;

    .orders-table {
        table {
            border-collapse: separate;
            border-spacing: 0 10px;

            thead {
                tr {
                    th {
                        font-size: pxToRem(14px);
                        color: $color-dark-lighter;
                        font-weight: 300;
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
            }

            tbody {
                tr {
                    min-height: pxToRem(55px);
                    height: 55px;
                    vertical-align: middle;
                    border-radius: 3px;
                    background: white;

                    td:first-child() {
                        border-left: 1px solid $color-gray-lightest;
                    }

                    td:last-child() {
                        border-right: 1px solid $color-gray-lightest;
                    }

                    td {
                        color: $color-dark-lighter;
                        font-size: pxToRem(14px);
                        font-weight: 500;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        border-top: 1px solid $color-gray-lightest;
                        border-bottom: 1px solid $color-gray-lightest;

                        .date {
                            font-weight: bold;
                            color: $color-blue-darker;
                            text-decoration: underline;
                        }

                        .state {
                            font-weight: bold;
                            color: $color-blue-normal;
                        }

                        a {
                            .icon {
                                max-width: pxToRem(15px);
                                min-width: pxToRem(15px);
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom-links {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: pxToRem(15px) 0;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                text-align: right;

                a {
                    font-weight: 500;
                    color: $color-blue-normal;
                    font-size: pxToRem(14px);
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    flex-direction: row;

                    .icon {
                        margin-left: pxToRem(10px);

                        img {
                            max-width: 6px;
                            width: 6px;
                        }
                    }
                }
            }
        }
    }
}

.news-wrap {
    background-color: $color-alert-primary;
    border: 1px solid $color-blue-light;
    border-radius: 3px;

    .bottom-links {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        padding: pxToRem(15px) 0;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                text-align: right;

                a {
                    font-weight: 500;
                    color: $color-blue-normal;
                    font-size: pxToRem(14px);
                    justify-content: flex-end;
                    align-items: center;
                    display: flex;
                    flex-direction: row;

                    .icon {
                        margin-left: pxToRem(10px);

                        img {
                            max-width: 6px;
                            width: 6px;
                        }
                    }
                }
            }
        }
    }
}
