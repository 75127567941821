$color-primary: #484344;
$color-primary-dark: #484344;
$color-primary-darker: #484344;
$color-secondary: #f20001;
$color-blue-sky: #00c3c3;
$color-blue-lighter: #a5e8e8;
$color-blue-darker: #00a0a0;
$color-blue-darker2: #008186;
$color-blue-darker3: #00acac;
$color-blue-advantage: #1facac;
$color-blue-normal: #29d6d6;
$color-gray-search-border: #e6dede;
$color-gray-darker: #625959;
$color-blue-lightest: #f4fafa;
$color-blue-light: #d1f2f2;
$color-gray: #7b6d6d;
$color-gray-lighter: #8c7d7d;
$color-gray-lightest: #e8e4e4;
$color-gray-lightest2: #f5f0f0;
$color-gray-lightest3: #fdf8f8;
$color-gray-lightest4: #f5f4f4;
$color-alert-primary: #f4faf9;
$color-gray-hr: #f0e7e7;
$color-dark: #000000;
$color-red-darker: #721c24;
$color-page-bg: #fafafa;
$color-gray-categories-hover: #a79898;
$color-red-categories-hover: #c93030;
$color-red-darker: #b42323;
$color-red-darkest: #9b0000;
$color-gray-product-price-wrap: #faf4f4;
$color-dark-lighter: #230d12;
$color-green-lighter: #68a800;
$color-orange: #ff3b00;
$color-brow-light: #c9b7b7;
$color-blue-dark: #0082bc;

$color-text-basic: #000000;
$color-text-light: #7e7e7e;
$color-text-lighter: #c4c4c2;
$color-text-inverse: #fff;
$color-text-complementary: #2db85e;

$color-light-box-bg: #f5f7f8;
$color-light-box-primary-bg: #2db85e;

$theme-colors: (
    'primary': $color-primary,
    'secondary': $color-secondary,
    'danger': #ff4136,
);

$color-input-bg: #AAAAAA;
$color-border-dark: #707070;
$color-complementary: #3a3a38;
$color-danger: #da4453;
$color-success: #2db85e;

/* $page-outer-width: 1400px;
$page-inner-width: 1300px;

$layout-column-width: 280px;

$font-size-base: 18px;

$page-padding-x: ($page-outer-width - $page-inner-width) / 2;

$button-height-base: 51px;

$cols-per-row: (1, 2, 3, 4, 5, 6, 12);

$gutter-width: 12px;
$gutter-half-width: 6px;
$gutter-wider-width: 36px;
$gutter-widest-width: 50px;
$grid-columns: 12;
$grid-breakpoints: (
  "xs": 0,
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
);

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex; */

// Alerts
//
// Define alert colors, border radius, and padding.

/* $alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-link-font-weight: 500;
$alert-border-width: 1px;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$theme-colors: (
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
);

$color-slider-selection: #4de384;
$color-slider-background: #c4c4c2;
$color-slider-view: #e6e7e7;
 */

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1165px,
    xxl: 1165px,
);

$breadcrumb-divider: quote('>');

$form-check-label-color: 'blue';
