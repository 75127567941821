.breadcrumbs {
  padding-top: pxToRem(12px);
  margin-left: pxToRem(25px);

  .breadcrumb {
    display: flex;
    color: white;
    margin-bottom: 0;

    li {
      font-weight: 200;
      font-size: pxToRem(12px);
      color: white;

      &::before {
        color: white;
      }

      a {
        color: white;
        font-weight: 200;
        font-size: pxToRem(12px);
      }
    }
  }

  &--dark {
    .breadcrumb {
      li {
        color: #230d12;

        &::before {
          color: #230d12;
        }

        a {
          color: #230d12;
        }
      }
    }
  }
}
