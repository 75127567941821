#snippet--flashMessages {
  position: fixed;
  z-index: 998;
  right: calc((100% - 1400px) / 2);
  top: 15px;
}

@media screen and (max-width: 1400px) {
  #snippet--flashMessages {
    right: 15px;
    margin-left: 15px;
  }
}

.alert {
  text-align: left;
  position: relative;
  padding: 10px 20px;
  @include clearfix;

  &__close-ico {
    float: right;
    margin: 4px -5px 0 20px;
    padding: 0;
    border: none;
    background: none;
  }

  &--success {
    background: #d4edda;
    color: #155724;

    .alert__close-ico {
      svg {
        fill: #155724;
      }

      &:hover {
        svg {
          fill: darken(#155724, 5%);
        }
      }
    }
  }

  &--danger {
    background: #f8d7da;
    color: #571515;

    .alert__close-ico {
      svg {
        fill: #571515;
      }

      &:hover {
        svg {
          fill: darken(#571515, 5%);
        }
      }
    }
  }

  &--warning {
    background: #fff3cd;
    color: #856404;

    .alert__close-ico {
      svg {
        fill: #856404;
      }

      &:hover {
        svg {
          fill: darken(#856404, 5%);
        }
      }
    }
  }

  &--info {
    background: #cce5ff;
    color: #004085;

    .alert__close-ico {
      svg {
        fill: #004085;
      }

      &:hover {
        svg {
          fill: darken(#004085, 5%);
        }
      }
    }
  }
}

.alert-container--connection-error,
.alert-container--general-error {
  display: none;
}
