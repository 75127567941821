.input-text {
  display: inline-block;
  font-size: 16px / $font-size-base * 1rem;
  line-height: 22px / $font-size-base * 1rem;
  padding: (16px / $font-size-base * 1rem) (20px / $font-size-base * 1rem) (13px / $font-size-base * 1rem);
  border: none;

  &[readonly], &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &--xl { // height: 64px
    font-size: pxToRem(26px);
    line-height: pxToRem(32px);
    padding: pxToRem(18px) pxToRem(20px) pxToRem(14px);
  }

  &--sm { // height: 46px
    font-size: pxToRem(17px);
    line-height: pxToRem(22px);
    padding: pxToRem(13px) pxToRem(16px) pxToRem(11px);
  }

  &--border {
    padding: (15px / $font-size-base * 1rem) (19px / $font-size-base * 1rem) (12px / $font-size-base * 1rem);
    border: 1px solid;
    border-color: $color-text-basic;

    &.input-text--xl { // height: 64px
      padding: pxToRem(17px) pxToRem(19px) pxToRem(13px);
    }

    &.input-text--sm { // height: 46px
      padding: pxToRem(12px) pxToRem(15px) pxToRem(10px);
    }
  }

  &--primary {
    background: $color-input-bg;
    color: $color-text-basic;
    border-color: $color-primary;

    &[readonly], &[disabled] {
      color: rgba($color-text-basic, 0.6);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-text-basic;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-text-basic;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: $color-text-basic;
    }
  }

  &--primary-dark {
    background: $color-primary-dark;
    border-color: $color-primary-darker;
    color: $color-text-basic;

    &[readonly], &[disabled] {
      color: rgba($color-text-basic, 0.6);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-text-basic;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-text-basic;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: $color-text-basic;
    }
  }

  &--primary-light {
    background: $color-primary;
    color: $color-text-basic;
    border-color: $color-text-complementary;

    &[readonly], &[disabled] {
      color: rgba($color-text-basic, 0.6);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-text-basic;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-text-basic;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: $color-text-basic;
    }
  }

  &--complementary {
    background: $color-complementary;
    border-color: $color-border-dark;
    color: $color-text-inverse;

    &[readonly], &[disabled] {
      color: rgba($color-text-inverse, 0.6);
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $color-text-inverse;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: $color-text-inverse;
    }

    &::placeholder { /* Most modern browsers support this now. */
      color: $color-text-inverse;
    }
  }

  &--light {
    background: #f5f7f8;
    border-color: #E8E8E8;
    color: $color-text-basic;

    &[readonly], &[disabled] {
      color: rgba($color-text-basic, 0.6);
    }
  }
}

label {
  > input[type=checkbox] {
    margin-right: 10px;
  }
}

.incrementable {
  display: inline-block;
  position: relative;

  &__input {
    width: 100%;
    text-align: center;
    padding-left: 28px;
    padding-right: 28px;
  }

  &__control {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    border: 1px solid #000;
    background: none;
    width: 28px;
    padding: 0;

    &:hover {
      background: #F5F7F8;
    }

    &--dec {
      left: 0;
    }

    &--inc {
      right: 0;
    }
  }

  &--xl {
    .incrementable__control {
      width: pxToRem(38px);
      font-size: pxToRem(26px);
    }

    .incrementable__input {
      padding-left: pxToRem(38px);
      padding-right: pxToRem(38px);
    }
  }

  &--sm {
    .incrementable__control {
      line-height: pxToRem(44px);
    }
  }
}

.form-std {
  &__input-group {
    margin-bottom: 20px;

    label:not(.custom-checkbox) {
      display: block;
      font-size: pxToRem(16px);
      margin-bottom: pxToRem(6px);
    }

    &.required {
      label:not(.custom-checkbox):after {
        content: ' *';
      }
    }

    .help-block {
      margin-top: pxToRem(6px);
    }
  }

  &__rating-group {
    margin-bottom: 20px;
  }

  .input-text {
    width: 100%;
  }

  textarea.input-text {
    resize: vertical;
  }
}

.custom-checkbox {
  display: flex;
  flex-wrap: nowrap;

  &__checkmark {
    display: block;
    width: pxToRem(30px);
    height: pxToRem(30px);
    position: relative;
    left: 0;
    top: 0;
    border: 1px solid $color-text-basic;
    background-color: #fff;
    flex: 0 0 auto;

    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: pxToRem(7px);
      height: pxToRem(14px);
      margin-top: pxToRem(-11px);
      margin-left: pxToRem(-3px);
      border: solid white;
      border-width: 0 pxToRem(2px) pxToRem(2px) 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &__label {
    margin-top: pxToRem(3px);
    margin-left: pxToRem(20px);
  }

  &:hover {
    .custom-checkbox__checkmark {
      background: #F5F7F8;
    }
  }

  &__input {
    display: none;

    &:checked + .custom-checkbox__checkmark {
      background: $color-text-basic;

      &:after {
        display: block;
      }
    }
  }

  &--compact
  {
    font-size: pxToRem(14px);

    .custom-checkbox__checkmark {
      width: pxToRem(19px);
      height: pxToRem(19px);

      &:after {
        width: pxToRem(4.2px);
        height: pxToRem(8.4px);
        margin-top: pxToRem(-6px);
        margin-left: pxToRem(-3px);
        border-width: 0 pxToRem(2px) pxToRem(2px) 0;
      }
    }

    .custom-checkbox__label {
      margin-top: pxToRem(1px);
      margin-left: pxToRem(9px);
    }
  }
}

.form-input-message, .form-std .error li {
  font-size: pxToRem(16px);
  padding: pxToRem(14px) pxToRem(24px);
  font-weight: bold;
  margin-top: pxToRem(15px);
  display: block;

  &--danger {
    color: $color-text-inverse;
    background: $color-danger;
  }

  &--success {
    color: $color-text-inverse;
    background: $color-success;
  }
}

.form-std .error {
  padding-left: 0;

  .error-item {
    color: $color-text-inverse;
    background: $color-danger;
  }
}

.form-info-required {
  font-size: pxToRem(14px);
  color: #777;
}

.select-styled {
  position: relative;

  &__dummy-input {

  }

  &__arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -15px;
    background-color: #000;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: pxToRem(7px);
      height: pxToRem(7px);
      margin-top: pxToRem(-7px);
      margin-left: pxToRem(-3.5px);
      border: solid white;
      border-width: 0 pxToRem(2px) pxToRem(2px) 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  select {
    padding-right: 50px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    appearance: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    width: 100%;
    //font-family: inherit;
    //font-size: inherit;
    //cursor: inherit;
    //line-height: inherit;

    &::-ms-expand {
      display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
  }
}

.text-center {
  text-align: center;
}

.star-rating__stars {
  position: relative;
  height: 2rem;
  width: 10rem;
  background-image: url('../../img/theme/icons/icon-star-grey.svg');
  background-size: 2rem 2rem;
}

.star-rating__label {
  position: absolute;
  height: 100%;
  background-size: 2rem 2rem;
}

.star-rating__input {
  margin: 0;
  position: absolute;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.star-rating__stars .star-rating__label:nth-of-type(1) {
  z-index: 5;
  width: 20%;
}

.star-rating__stars .star-rating__label:nth-of-type(2) {
  z-index: 4;
  width: 40%;
}

.star-rating__stars .star-rating__label:nth-of-type(3) {
  z-index: 3;
  width: 60%;
}

.star-rating__stars .star-rating__label:nth-of-type(4) {
  z-index: 2;
  width: 80%;
}

.star-rating__stars .star-rating__label:nth-of-type(5) {
  z-index: 1;
  width: 100%;
}

.star-rating__input:checked + .star-rating__label,
.star-rating__input:focus + .star-rating__label,
.star-rating__label:hover {
  background-image: url('../../img/theme/icons/icon-star-green.svg');
}

.star-rating__label:hover ~ .star-rating__label {
  background-image: url('../../img/theme/icons/icon-star-grey.svg');
}

.phone-select-prefix {
  width: 20% !important;
  float: left;
}

.phone-input {
  width: 75% !important;
  margin-left: 5%;
}
