.page-banner {
    &--red {
        .container {
            background: rgba($color-red-darkest, 0.79);
        }

        @media only screen and (max-width: 1200px) {
            background: rgba($color-red-darkest, 0.79);

            .container {
                background: transparent;
            }
        }
    }

    &--white {
        .container {
            background: #fff;
        }
    }

    &--hasbg {
        .container {
            z-index: 10;

            .row {
                z-index: 10;

                .content-col {
                    z-index: 10;
                }
            }
        }
    }

    &.page-banner__home {
        position: relative;
        z-index: 0;

        .page-banner__content {
            padding: pxToRem(12px) 0;

            .images-wrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                justify-content: space-between;

                @media screen and (max-width: 768px) {
                    .left-col,
                    .right-col {
                        display: none;
                    }

                    .middle-col {
                        margin: auto;
                        max-width: 85%;

                        img {
                            max-width: 100%;
                        }
                    }
                }
            }
        }

        .container {
            background: transparent;

            z-index: 10;

            .content-col {
                /*                 margin-left: pxToRem(50px);
 */
                .homepage-logo {
                    margin-bottom: pxToRem(35px);

                    img {
                        max-width: 410px;
                        height: auto;
                        display: block;

                        @media only screen and (max-width: 768px) {
                            display: block;
                            max-width: 100%;
                            margin: auto !important;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        margin-bottom: pxToRem(15px);

                        img {
                            max-width: 290px;
                            margin-right: auto !important;
                            margin-left: inherit !important;
                        }
                    }
                }

                .homepage-claim {
                    margin-bottom: pxToRem(15px);

                    img {
                        display: block;
                        max-width: 100%;
                        height: auto;

                        @media only screen and (max-width: 768px) {
                            display: block;
                            max-width: 100%;
                            margin-right: auto !important;
                            margin-left: inherit !important;
                        }
                    }
                }
            }
        }

        .page-bg-inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    &.page-banner-searchresults {
        position: relative;
        z-index: 0;
        display: flex;
        flex-direction: column;

        .page-bg-inner {
            background-image: url('#{$relative-path-to-images}/img/page-bg-search.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            min-width: 1920px;
            max-width: 1920px;
            min-height: 930px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: -1;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }

    &.page-banner__supercategory {
        position: relative;
        z-index: 0;

        .container {
            z-index: 10;
            position: relative;
        }

        .page-bg-inner {
            background-image: url('#{$relative-path-to-images}/img/superaktegorie_barvy.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            min-width: 1920px;
            max-width: 1920px;
            min-height: 880px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 0;

            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }

        @media only screen and (max-width: 1200px) {
            position: inherit !important;
            z-index: inherit !important;

            .container {
                z-index: inherit !important;
                position: inherit !important;
            }
        }
    }

    .page-outer-banners {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;

        @media only screen and (max-width: 1200px) {
            display: none !important;
        }

        .banner {
            max-width: 370px;
            min-width: 370px;
            height: auto;
            display: block;
            position: absolute;
            top: 0;

            &-left {
                left: -370px;
            }

            &-right {
                right: -370px;
            }
        }
    }

    &__content {
        padding: pxToRem(48px) 0;

        .info-col {
            margin-top: pxToRem(-85px);
            padding-right: pxToRem(15px);

            .content-wrapper {
                position: relative;
                background-color: $color-red-darkest;
                padding: pxToRem(55px) pxToRem(35px);
                padding-bottom: 0;

                .title {
                    display: flex;
                    align-items: center;

                    .icon-wrap {
                        max-width: pxToRem(20px);
                        min-width: pxToRem(20px);
                        width: pxToRem(20px);
                        margin-right: pxToRem(10px);
                    }

                    margin-bottom: pxToRem(25px);
                }

                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -90px;
                    width: 100%;
                    height: auto;
                    background-image: url('#{$relative-path-to-images}/img/page-banner-info-col-bottom-2.png');
                    background-size: contain;
                    min-height: 92px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    &--red-bottom-waves {
        position: relative;

        .bg-container {
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                max-width: 1165px;
                height: auto;
                bottom: pxToRem(-300px);
                left: 0;
                right: 0;
                margin: auto;
                background-image: url('#{$relative-path-to-images}/img/red-banner-bottom-bg.png');
                background-size: contain;
                min-height: pxToRem(300px);
                background-repeat: no-repeat;

                @media only screen and (max-width: 992px) {
                    width: 142%;
                    left: -15%;
                    right: inherit;
                    margin: inherit;
                }

                @media only screen and (max-width: 768px) {
                    width: 142%;
                    left: -21%;
                    right: inherit;
                    margin: inherit;
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .page-banner__content {
            padding: pxToRem(30px) 0;
        }

        .row {
            position: relative;
        }

        .content-col {
            padding-left: 55px;

            h1 {
                font-size: pxToRem(22px);
            }

            h2 {
                font-size: pxToRem(14px);
                margin-bottom: pxToRem(20px);
            }

            .description-wrap {
                font-size: pxToRem(12px);
                line-height: pxToRem(16px);
                max-width: pxToRem(370px);

                p {
                    font-size: pxToRem(14px);
                    line-height: pxToRem(16px);
                }
            }
        }

        .info-col {
            position: absolute;
            margin-top: 0;
            top: -60px;
            right: 0;
            min-width: pxToRem(230px);

            .title {
                font-size: pxToRem(14px);
            }

            .content-wrapper {
                padding-top: pxToRem(45px);
                padding-left: pxToRem(15px);
                padding-right: pxToRem(15px);
                padding-bottom: pxToRem(5px);
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .content-col {
            padding-left: 15px;

            h1 {
                font-size: pxToRem(22px);
            }

            h2 {
                font-size: pxToRem(14px);
                margin-bottom: pxToRem(20px);
            }

            .description-wrap {
                font-size: pxToRem(12px);
                line-height: pxToRem(16px);
                max-width: inherit !important;
                margin-bottom: pxToRem(45px);

                p {
                    font-size: pxToRem(12px);
                    line-height: pxToRem(16px);
                }
            }
        }

        .info-col {
            position: absolute;
            margin-top: 0;
            top: -60px;
            right: 25px;
            min-width: pxToRem(50px);
            max-width: pxToRem(50px);
            width: 50px;
            height: 50px;
            align-items: center;
            justify-content: center;
            background: #9b040a;
            padding: 0;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;

            .title {
                display: none;
            }

            .content-wrapper {
                padding: 0 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                background: transparent !important;

                ul {
                    display: none;
                }

                .title {
                    margin-bottom: 0;

                    span {
                        display: none;
                    }

                    .icon-wrap {
                        margin-right: 0;
                    }
                }

                &::after {
                    display: none;
                }
            }

            .modal-opener {
                -webkit-appearance: none;
                appearance: none;
                border: none;
                background: transparent;
            }

            .modal-header {
                .btn-close {
                    font-weight: bold;
                    color: $color-blue-darker2;

                    &.custom-close {
                        background: url('#{$relative-path-to-images}/img/ico-close.svg');
                        background-size: cover;
                        width: 5px;
                        height: 5px;
                        margin-right: 0;
                        margin-top: -15px !important;
                        opacity: 1;
                    }
                }
            }

            .modal-body {
                padding-left: pxToRem(50px) !important;
                padding-right: pxToRem(50px) !important;

                ul {
                    display: block;
                    margin-top: pxToRem(25px) !important;
                }
            }

            .modal-content {
                max-width: pxToRem(270px);
                margin: auto;
                background-color: #f4faf9;
                border: 1px solid #d1f2f2;
                border-radius: 3px;

                .title {
                    span {
                        display: block !important;
                        font-size: pxToRem(14px);
                        font-weight: 500;
                        color: $color-dark;
                        margin-left: pxToRem(10px);
                    }
                }

                ul {
                    li {
                        color: #a5e8e8 !important;

                        a {
                            color: $color-dark !important;
                        }
                    }
                }
            }
        }
    }
    &--red-bottom-waves {
        .bg-container:after {
            bottom: -235px;
            min-height: 235px;
        }
    }
}
