.shops-block {
    .row {
        z-index: 10;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .consueling-col {
        .content-wrap {
            position: relative;

            background-image: url('#{$relative-path-to-images}/img/bg-consueling-hp.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            .content {
                background-image: url('#{$relative-path-to-images}/img/bg-hp-poradna.jpg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: relative;

                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -90px;
                    width: 100%;
                    height: auto;
                    background-image: url('#{$relative-path-to-images}/img/bg-bottom-green.png');
                    background-size: contain;
                    min-height: 90px;
                    background-repeat: no-repeat;
                }
            }
        }

        @media only screen and (max-width: 992px) {
            h3 {
                font-size: pxToRem(33px);
                margin-bottom: pxToRem(10px);
            }

            .subtitle {
                margin-bottom: pxToRem(15px);
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .shops-col {
            .content-wrap {
                padding-left: 0 !important;
                padding-right: 0 !important;
                margin-top: pxToRem(55px) !important;
            }
        }
    }
}
