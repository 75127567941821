.sidebar-wrapper {
    .widget-item {
        background-color: #faf4f4;
        margin-bottom: pxToRem(15px);

        .title {
            padding: pxToRem(10px);
            border-top: 1px solid $color-gray-hr;
            border-bottom: 1px solid $color-gray-hr;
            font-size: pxToRem(15px);
            font-weight: 800;
            color: $color-gray;
            background-color: #fdf8f8;

            display: flex;
            cursor: pointer;

            .icon {
                margin-left: auto;
                img {
                    max-width: 16px;
                    width: 16px;
                    height: auto;
                    display: block;
                }
            }
        }

        .filters, .read-more-filters {
            transition: display 0.6s linear;
            display: none;
        }

        &.shown-filters {
            padding-bottom: pxToRem(5px);
            .filters  {
                display: block;
            }
            .title {
                .icon {
                    -webkit-transform: rotate(180deg);
                    -ms-transform: rotate(180deg);
                    transform: rotate(180deg);
                }
            }
            .read-more-filters {
                display: block;
            }
        }

        &.shown-items {
            .read-more-filters  {
                display: none;
            }
        }

        .show-more {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-brow-light;

            .icon {
                img {
                    max-width: 8px;
                    width: 8px;
                    height: auto;
                    display: block;
                }
            }
        }

        .custom-checkbox {
            display: flex;
            align-items: center;
            margin-bottom: pxToRem(15px);

            label {
                span {
                    color: $color-brow-light;
                }
            }

            &.disabled {
                label {
                    color: $color-brow-light;
                }
            }

            input[type='checkbox'] {
                width: 13px !important;
                height: 13px !important;
                outline: 1px solid #f20001;
                border-radius: 2px;
                padding: 1px;
                cursor: pointer;
                margin: 0 !important;
            }

            input:checked[type='checkbox'] {
                background-image: url('#{$relative-path-to-images}/img/check.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-color: transparent !important;
            }
        }

        ul {
            list-style-type: none;
            padding: pxToRem(15px) pxToRem(10px) 0 pxToRem(10px);

            li {
                margin-bottom: pxToRem(10px);
                cursor: pointer;

                label {
                    color: $color-red-categories-hover;
                    line-height: pxToRem(13px);
                    padding-left: pxToRem(7px);
                    cursor: pointer;
                }

                input:checked {
                    background-color: $color-red-categories-hover;
                }

                &.hidden {
                    display: none;
                }
            }
        }
    }

    &.mobile-aside {
        z-index: 10000000000;
        position: relative;
    }
}
