body {
    font-family: 'urw-form', sans-serif;
    font-weight: 300;
    background-color: #fafafa;
}

main {
    position: relative;
    overflow: hidden;
}

.page-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    z-index: -3;

    img {
        width: 100%;
        object-fit: cover;
        max-height: 100%;
    }
}

.btn {
    background-color: #00a0a0;
    color: white;
    font-size: pxToRem(12px);
    -webkit-appearance: inherit;
    appearance: inherit;

    &:focus {
        outline: none;
        box-shadow: none !important;
    }

    &.big {
        min-width: pxToRem(185px);
    }

    &.normal {
        min-width: pxToRem(155px);
    }

    &.medium {
        min-width: pxToRem(105px);
    }

    &.max-w-big {
        max-width: pxToRem(200px);
    }

    &.min-h-big {
        min-height: pxToRem(45px);
    }

    &.centered {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.bigger-font {
        font-size: pxToRem(18px);
    }

    &.bigger-padding {
        padding: pxToRem(10px) pxToRem(20px);
    }

    &.read-more {
        .icon {
            margin-left: pxToRem(10px);
            margin-right: 0 !important;
            transition: all 0.25s ease-in-out;
        }

        &.show-more {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &.show-all-filters {
        padding: pxToRem(10px);
        border-top: 1px solid $color-gray-hr;
        border-bottom: 1px solid $color-gray-hr;
        font-size: pxToRem(15px);
        font-weight: 800;
        color: $color-gray !important;
        background-color: #fdf8f8;

        @media only screen and (max-width: 768px) {
            border: 2px solid $color-secondary;
        }

        .icon {
            margin-left: auto;
            margin-right: inherit !important;
            transition: all 0.25s ease-in-out;
            width: 100% !important;

            @media only screen and (max-width: 500px) {
                height: 100%;
                min-height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &:not(.show-more) {
            .icon {
                transform: rotate(180deg);
            }
        }

        &.show-more {
            .icon {
                width: 14px;

                img {
                    width: 14px !important;
                    max-width: 14px !important;
                    margin-left: 0 !important;

                    @media only screen and (max-width: 500px) {
                        width: 20px !important;
                        max-width: 20px !important;
                    }
                }
            }
        }
    }

    &.medium-font {
        font-size: pxToRem(15px);
    }

    &:hover {
        color: white;
        text-decoration: underline;
    }

    &.bg-white {
        background-color: white;
    }

    &.cart-button-wide {
        min-width: pxToRem(275px);

        .content {
            min-width: pxToRem(255px) !important;
        }
    }

    &.cart-button-left {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding-left: pxToRem(11px);

        &:focus {
            outline: none;
            box-shadow: none;
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            border: 1px solid;
            border-left: 0;
            min-width: 185px;
            padding-right: pxToRem(20px);
            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
            gap: 7px;

            .icon {
                max-width: 18px;
                width: 18px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: auto;
            }
        }

        .left-arrow {
            display: flex;

            .triangle-left {
                width: 0;
                height: 0;
                border-top: 23px solid transparent;
                border-bottom: 23px solid transparent;
                border-right: 23px solid $color-blue-darker;
            }

            .inner-triangle {
                position: relative;
                top: -22px;
                left: 1.23px;
                width: 0;
                height: 0;
                border-top: 22px solid transparent;
                border-bottom: 22px solid transparent;
                border-right: 22px solid white;
            }
        }

        &.cart-button-white-red {
            background: transparent;
            min-height: 46px;
            height: 46px;
            color: #e85555;
            border: none !important;

            .left-arrow {
                .triangle-left {
                    border-right-color: #e85555 !important;
                }
            }
        }

        &.cart-button-white-green {
            background: transparent;
            min-height: 46px;
            height: 46px;
            color: $color-blue-darker;
            border: none;

            .arrow.top {
                border-color: $color-blue-darker !important;
            }

            .arrow.bottom {
                border-color: $color-blue-darker !important;
            }
        }
    }

    &.cart-button-right {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 50px;
        padding-right: pxToRem(11px);
        border: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }

        .content {
            display: flex;
            align-items: center;
            min-width: 185px;
            justify-content: center;
            height: 100%;
            border-right: 0;
            padding-left: pxToRem(20px);
            border-top-left-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            z-index: 10;
            gap: 7px;

            .icon {
                max-width: 18px;
                width: 18px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: auto;
            }
        }

        .right-arrow {
            display: flex;

            .triangle-right {
                width: 0;
                height: 0;
                border-top: 23px solid transparent;
                border-bottom: 23px solid transparent;
                border-left: 23px solid $color-blue-darker;
            }

            .inner-triangle {
                position: relative;
                top: -20px;
                right: 22px;
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 20px solid $color-blue-darker;
            }
        }

        .left-arrow {
            display: flex;

            .triangle-left {
                width: 0;
                height: 0;
                border-top: 23px solid transparent;
                border-bottom: 23px solid transparent;
                border-left: 23px solid $color-blue-darker;
            }

            .inner-triangle {
                position: relative;
                top: -20px;
                left: 22px;
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-bottom: 20px solid transparent;
                border-left: 20px solid white;
            }
        }

        &.cart-red {
            background: transparent;
            min-height: 46px;
            height: 46px;
            color: white;
            border-color: #e85555;

            .content {
                background-color: #e85555;
            }

            .triangle-right {
                border-left-color: #e85555 !important;
            }

            .inner-triangle {
                border-left-color: #e85555 !important;
            }
        }

        &.cart-green {
            background: transparent;
            min-height: 46px;
            height: 46px;
            color: white;
            border-color: $color-blue-darker;

            .content {
                background-color: $color-blue-darker;
            }

            .arrow.top {
                border-color: $color-blue-darker !important;
                background-color: $color-blue-darker;
            }

            .arrow.bottom {
                border-color: $color-blue-darker !important;
                background-color: $color-blue-darker;
            }
        }
    }

    &.add-to-cart-product {

        &:not(.cart-btn-varitant) {
            max-width: 185px !important;
            min-height: 38px !important;
        }

        .icon {
            margin-right: 15px !important;
            width: 15px !important;
            max-width: 15px !important;
            min-width: 15px !important;
        }

        &:hover {
            background-color: $color-blue-darker3 !important;
        }
    }

    &.full-height {
        height: 100%;
    }

    &.inline {
        display: inline-flex !important;
    }

    &.no-bg {
        background-color: inherit;
    }

    &.blue-darker-txt-border {
        border-color: $color-blue-darker;
        color: $color-blue-darker;
    }

    &.with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: initial;

        .icon {
            width: pxToRem(14px);
            min-width: pxToRem(14px);
            max-width: pxToRem(14px);
            margin-right: pxToRem(5px);
        }

        &:hover {
            text-decoration: none !important;
        }
    }

    &.red {
        background-color: $color-secondary;
    }
}

.list-blue-darker2 {
    padding: pxToRem(25px);

    li {
        margin-bottom: pxToRem(20px);

        &::marker {
            color: $color-blue-lighter;
        }

        color: $color-blue-darker2;
        font-size: pxToRem(14px);

        a {
            color: $color-blue-darker2;
            font-size: pxToRem(14px);
        }
    }
}

.list-with-dash {
    li {
        list-style-type: '-';
        padding-inline-start: 1ch;
    }
}

.list-with-alphabet {
    list-style-type: lower-alpha;
}

.list-with-red-line {
    position: relative;

    &:before {
        width: 1px;
        height: 62%;
        background: $color-secondary;
        content: '';
        position: absolute;
        left: 2rem;
        top: 6%;
    }

    li {
        list-style: none;
        padding-inline-start: 1ch;
    }
}

.list-with-arrows {
    li {
        margin-bottom: pxToRem(15px);

        a {
            &::before {
                content: url('#{$relative-path-to-images}/img/arrow-right.svg');
                display: inline-block;
                width: 100%;
                max-width: 8px;
                margin-right: pxToRem(6px);
                font-weight: normal;
            }
        }
    }
}

.list-dark {
    li {
        color: $color-dark;
        font-weight: 400;
    }
}

.list-wo-padding {
    padding: 0;
    list-style-position: inside;

    li {
        list-style-position: outside;
        margin-left: 1em;
    }
}

.list-white {
    li {
        color: white;

        a {
            color: white;
        }
    }

    &.smaller {
        font-size: pxToRem(12px);
    }

    &.with-margins {
        li {
            margin-bottom: pxToRem(15px);
        }

        li:last-child() {
            margin-bottom: 0;
        }
    }
}

.download-list {
    padding-left: 5px;

    li {
        list-style-position: outside;
        margin-left: 1em;

        &::marker {
            color: $color-blue-lighter;
        }

        a {
            color: $color-blue-darker;
            font-weight: 500;
        }
    }
}

.input-group {
    button {
        height: 100%;
    }
}

input[type='checkbox'] {
    width: 30px !important;
    height: 30px !important;
    margin: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 2px solid lightblue;
    box-shadow: none;
    font-size: 2em;
}

.single-product-aside-list {
    padding-left: 5px;

    li {
        list-style-position: outside;
        margin-left: 1em;

        &::marker {
            color: $color-blue-lighter;
        }
    }
}

.content-list-gray {
    padding-left: 0;
    list-style-position: inside;

    li {
        margin-bottom: pxToRem(12px);

        &::marker {
            color: $color-gray;
        }

        a {
            color: $color-gray;
            font-weight: 300;
        }
    }
}

.list-gray-darker-wo-padding {
    padding: 0;
    list-style-position: inside;

    li {
        color: $color-gray-darker;
        font-weight: 400;
        font-size: pxToRem(16px);
    }
}

blockquote {
    padding: pxToRem(25px);
    background-color: $color-gray-lightest2;
    color: $color-dark;
}

.form-wrapper {
    background-color: $color-alert-primary;
    border: 1px solid $color-blue-light;
    border-radius: 3px;
    box-shadow: 0px 6px 12px 0px rgba(244, 250, 250, 1);

    label {
        color: $color-blue-darker2;
        font-weight: bold;
        font-size: pxToRem(14px);
    }

    .form-control {
        min-height: pxToRem(50px);
        color: $color-blue-darker2;
        font-weight: 400;
        border: 2px solid $color-blue-lighter;
        background-color: white;
        font-size: pxToRem(14px);

        &:focus {
            box-shadow: none !important;
        }
    }

    .form-check-inline {
        padding-left: pxToRem(50px);
    }

    .form-check-input:checked {
        background-color: $color-blue-darker2;
        border-color: $color-blue-darker2;
    }

    input[type='checkbox'] {
        width: 25px !important;
        height: 25px !important;
        cursor: pointer !important;
        border-radius: 3px !important;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: $color-blue-darker2;
        font-weight: 300;
        opacity: 1;
    }

    input:-moz-placeholder,
    textarea:-moz-placeholder {
        color: $color-blue-darker2;
        font-weight: 300;
        opacity: 1;
    }
}

form {
    .form-control {
        min-height: pxToRem(50px);
        color: $color-blue-darker2;
        font-weight: 300;
        border: 2px solid $color-blue-lighter;
        background-color: white;
        font-size: pxToRem(14px);
    }

    &.custom-form {
        label {
            color: $color-blue-darker2;
            font-weight: bold;
            font-size: pxToRem(14px);
        }

        .form-control {
            min-height: pxToRem(50px);
            color: $color-blue-darker2;
            font-weight: 300;
            border: 2px solid $color-blue-lighter;
            background-color: white;
            font-size: pxToRem(14px);
        }

        .form-check-inline {
            padding-left: pxToRem(45px);
        }

        .form-check-input:checked {
            background-color: $color-blue-darker2;
            border-color: $color-blue-darker2;
        }

        input[type='checkbox'] {
            width: 23px !important;
            height: 23px !important;
            cursor: pointer !important;
            border-radius: 3px !important;
            outline: 2px solid $color-blue-lighter;
            border: none !important;
            margin-left: -1.425em;

            &:focus {
                box-shadow: none !important;
            }
        }

        input::-webkit-input-placeholder,
        textarea::-webkit-input-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }

        input:-moz-placeholder,
        textarea:-moz-placeholder {
            color: $color-blue-darker2;
            font-weight: 300;
            opacity: 1;
        }
    }
}


@media only screen and (max-width: 768px) {
    .newsletter-mobile {
        margin-top: 16px;
        margin-bottom: 16px;

        .form-input-message {
            width: 100%;
        }
    }
}
