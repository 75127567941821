.promoblocks-halftohalf {
    margin-top: pxToRem(30px);

    .promoblock-half {
        min-height: pxToRem(450px);
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        display: flex;

        .content-wrap {
            max-width: pxToRem(395px);
            padding-top: pxToRem(60px);
            padding-left: pxToRem(40px);
            padding-right: pxToRem(40px);
            position: relative;

            a {
                color: white;
            }
        }

        &.promo-block-red {
            .content-wrap {
                background-color: $color-red-darkest;

                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -90px;
                    width: 100%;
                    height: auto;
                    background-image: url('#{$relative-path-to-images}/img/promo-half-red-bottom.png');
                    background-size: contain;
                    min-height: 90px;
                    background-repeat: no-repeat;
                }
            }
        }

        &.promo-block-gray {
            .content-wrap {
                background-color: $color-gray-darker;

                &::after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: -90px;
                    width: 100%;
                    height: auto;
                    background-image: url('#{$relative-path-to-images}/img/promo-half-gray-bottom.png');
                    background-size: contain;
                    min-height: 90px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .promo-bg-shop {
        background-image: url('#{$relative-path-to-images}/img/promo-half-left.jpg');
    }

    .promo-bg-painter {
        background-image: url('#{$relative-path-to-images}/img/promo-right-half.jpg');
    }

    @media only screen and (max-width: 992px) {
        padding-top: pxToRem(15px);
        margin-top: 0;
        background-color: white;

        .promoblock-half {
            min-height: 315px;
            margin-bottom: pxToRem(15px);

            h3 {
                font-size: pxToRem(21px);
            }

            .content-wrap {
                max-width: pxToRem(275px);

                p {
                    font-size: pxToRem(12px);
                    line-height: pxToRem(16px);
                }
            }
        }

        .promo-bg-painter {
            background-position: 12% 21%;
        }
    }
}
