.products-grid {
    &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(255px, auto));
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        grid-template-rows: max-content;

        &.twoproducts {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }

        &.threeproducts {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(255px, auto));
            grid-column-gap: 30px;
            grid-row-gap: 30px;
        }

        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(3, 1fr) !important;
            grid-column-gap: 15px;
            grid-row-gap: 15px;

            &.grid-with-side {
                grid-template-columns: repeat(2, 1fr) !important;
                grid-column-gap: 15px;
                grid-row-gap: 15px;
            }

            .products-grid__product {
                //overflow: hidden;
                min-width: 0;
            }

            .products-grid__product__bottomrow {
                gap: 0 10px;

                .add-to-card-btn {
                    min-width: inherit !important;
                    a {
                        font-size: pxToRem(9px);
                        min-width: inherit;
                    }
                }

                .quantity {
                    input {
                        max-width: 60px;
                        min-width: 60px;
                    }
                }
            }
        }

        @media only screen and (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr) !important;
        }

        @media only screen and (max-width: 465px) {
            grid-template-columns: repeat(1, 1fr) !important;
            grid-column-gap: 15px;
            grid-row-gap: 15px;

            &.grid-with-side {
                grid-template-columns: repeat(1, 1fr) !important;
                grid-column-gap: 15px;
                grid-row-gap: 15px;
            }
        }
    }

    &__product {
        background: #ffffff;
        border: 1px solid #faf4f4;
        border-radius: 3px;
        padding: pxToRem(12px);
        box-shadow: 6px 6px 16px 0 rgba(0, 0, 0, 0.1);
        min-height: 100%;
        display: flex;
        flex-direction: column;

        &__title {
            font-weight: bold;
            font-size: pxToRem(15px);
            text-align: left;
            text-transform: uppercase;
            flex: 1 0 auto;
        }

        &__image {
            max-height: 280px;
            width: 100%;
            height: 100%;
            border-bottom: 1px solid #d3c9c9;
            padding-bottom: pxToRem(15px);
            padding-top: pxToRem(30px);

            img {
                object-fit: contain;
                display: block;
                max-width: 100%;
                width: 100%;
                height: 100%;
                max-height: 235px;
            }
        }

        &__price {
            span {
                color: $color-secondary;
                font-weight: bold;
                font-size: pxToRem(23px);
            }

            del {
                color: $color-gray-categories-hover;
                font-size: pxToRem(14px);
                margin-left: pxToRem(15px);
            }

            &.with-addtocartbutton {
                display: flex;
                flex-direction: row;

                .price {
                    display: flex;
                    flex-direction: column-reverse;
                    text-align: left;
                    justify-content: flex-start;
                    align-items: flex-start;

                    del {
                        margin-left: 0;
                    }
                }

                .add-to-card-btn {
                    margin-left: auto;
                    height: auto;
                }
            }
        }

        .product-card {
            &__input-row {
                margin-top: 18px;
            }
        }

        &__bottomrow {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            gap: 0 10px;

            max-height: pxToRem(35px);
            min-height: pxToRem(35px);

            .quantity {
                input {
                    background: white;
                    color: $color-gray;
                    border: 1px solid $color-gray-lightest4;
                    border-radius: 0;
                    margin: 0 1px;
                    text-align: center;
                    font-weight: bold;
                    font-size: pxToRem(17px);

                    &:focus {
                        outline: none;
                    }
                }

                .quantity-value-buttons {
                    background: $color-gray-lightest2;
                    display: flex;
                    border-left: 1px solid $color-gray-lightest3;
                    flex-direction: column;
                    min-width: pxToRem(23px);
                    align-items: center;
                    justify-content: center;

                    &.plus {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }

                    &.minus {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }

                    a {
                        display: flex;
                        text-align: center;
                        text-decoration: none;
                        justify-content: center;
                        color: $color-gray;
                        font-weight: bold;
                        font-size: pxToRem(15px);
                        line-height: pxToRem(17px);
                        width: 100%;
                        min-width: 30px;

                        /*    &:first-child {
                            border-bottom: 1px solid $color-gray-lightest3;
                        } */
                    }
                }

                .add-to-card-btn {
                    a {
                        font-weight: 300;
                    }
                }
            }
        }

        &.promoblock-bigblock {
            grid-column: span 3;

            @media only screen and (max-width: 768px) {
                grid-column: span 2;
            }

            @media only screen and (max-width: 470px) {
                grid-column: span 1;
            }

            .content-wrap {
                .top-row {
                    padding: pxToRem(50px);
                    background-color: $color-red-darkest;
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    transition: all 0.25s ease-in-out;

                    .info-image {
                        position: absolute;
                        top: 0;
                        right: 50px;

                        @media only screen and (max-width: 1200px) {
                            display: none;
                        }
                    }
                }

                .bottom-row {
                    padding: pxToRem(30px);
                }

                a {
                    color: white;
                }

                @media only screen and (max-width: 470px) {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;

                    .top-row,
                    .bottom-row {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }

                    .bottom-row {
                        .content-wrap {
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                        }
                    }
                }
            }

            &:hover {
                .top-row {
                    background-color: $color-secondary;
                }
            }

            @media only screen and (max-width: 992px) {
                .content-wrap {
                    .top-row {
                        padding: pxToRem(35px);

                        .logo-image {
                            img {
                                max-width: pxToRem(185px);
                            }
                        }

                        .info-image {
                            display: block;
                            max-width: pxToRem(255px);
                            right: pxToRem(20px);
                        }
                    }

                    .bottom-row {
                        .content-wrap {
                            max-width: pxToRem(180px);
                        }
                    }

                    h2 {
                        font-size: pxToRem(21px);
                        font-weight: 900;
                        margin-bottom: pxToRem(10px);
                    }

                    p {
                        font-size: pxToRem(12px);
                        line-height: pxToRem(16px);
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .content-wrap {
                    .top-row {
                        .info-image {
                            display: block;
                            max-width: pxToRem(180px);
                            right: pxToRem(20px);
                        }
                    }
                }
            }

            @media only screen and (max-width: 470px) {
                grid-column: span 1;

                .info-image {
                    display: none !important;
                }
            }
        }
    }

    &__similar {
        display: grid;
        grid-template-rows: max-content;
        grid-template-columns: repeat(auto-fit, minmax(255px, auto));
        grid-column-gap: pxToRem(32px);
        grid-row-gap: pxToRem(32px);
    }

    &__results {
        span {
            color: $color-red-categories-hover;
            font-weight: 600;
        }
    }

    &__filter {
        align-items: center;
        justify-content: center;

        span {
            margin-right: pxToRem(10px);
        }

        select {
            max-width: pxToRem(200px);
            color: $color-gray-darker;
            -webkit-appearance: none !important;
            border-color: #e6dede !important;

            &:focus {
                outline: none !important;
                box-shadow: none !important;
                border-color: #e6dede;
            }
        }

        @media only screen and (max-width: 992px) {
            span {
                display: none;
            }
            select {
                min-width: pxToRem(170px);
                font-size: pxToRem(13px);
            }
        }
    }

    @media only screen and (max-width: 992px) {
        &__filters-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;

            .products-grid__results,
            .products-grid__filter {
                flex: 1;
            }
        }
    }

    &__product
    {
        padding-bottom: 30px;
        &__bottomrow {
            .incrementable__input {
                width: auto;
                padding: 0;
            }
        }
    }
}

.products-grid-slider {
    display: flex;

    /* the slides */
    .slick-slide {
        margin: 0 15px;
    }
    /* the parent */
    .slick-list {
        margin: 0 -15px;
    }

    .products-grid__product__image {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-height: pxToRem(250px);
        }
    }

    .slick-prev {
        left: -55px;
        z-index: 100;

        &::before {
            color: $color-gray-lighter;
        }

        @media only screen and (max-width: 992px) {
            left: -30px;
        }
    }

    .slick-next {
        right: -55px;
        z-index: 100;

        &::before {
            color: $color-gray-lighter;
        }

        @media only screen and (max-width: 992px) {
            right: -30px;
        }
    }

    @media only screen and (max-width: 992px) {
        .products-grid__product {
            overflow: hidden;
            min-width: 0;
        }

        .products-grid__product__bottomrow {

            .add-to-card-btn {
                min-width: inherit !important;
                a {
                    font-size: pxToRem(9px);
                    min-width: inherit;
                }
            }

            .quantity {
                input {
                    max-width: 60px;
                    min-width: 40px;
                }
            }
        }

        .products-grid__product__image {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: pxToRem(200px);
                min-height: pxToRem(200px);
            }
        }
    }
}

.products-grid-wrapper, .filter-area {
    z-index: 1;
}
