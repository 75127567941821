.promo-blocks {
    position: relative;
    z-index: 5;

    &--whitebg {
        .container {
            background-color: rgba(255, 255, 255, 0.85);
        }
    }

    &__smallblock {
        z-index: 10;

        .block-content {
            padding: pxToRem(25px);
            padding-bottom: pxToRem(40px);

            h3 {
                margin-bottom: pxToRem(20px);
            }

            a {
                color: white;
            }

            @media only screen and (max-width: 992px) {
                h3 {
                    font-size: pxToRem(14px);
                }

                p {
                    font-size: pxToRem(12px);
                }
            }
        }

        &.block-blue-sky {
            .block-content {
                transition: all 0.25s ease-in-out;
            }
            &:hover {
                .block-content {
                    background-color: $color-blue-darker;
                }
            }
        }

        &.block-red {
            .block-content {
                transition: all 0.25s ease-in-out;
            }
            &:hover {
                .block-content {
                    background-color: $color-secondary;
                }
            }
        }

        &.block-rounded {
            .block-wrap {
                border-radius: 3px;
            }
        }
    }

    &__bigblock {
        .content-wrap {
            .top-row {
                padding: pxToRem(50px);
                background-color: $color-gray-darker;
                display: flex;
                flex-direction: row;
                position: relative;
                transition: all 0.25s ease-in-out;

                .info-image {
                    position: absolute;
                    top: 0;
                    right: 50px;

                    @media only screen and (max-width: 1200px) {
                        display: none !important;
                    }
                }
            }

            .bottom-row {
                padding: pxToRem(30px);
            }

            a {
                color: white;
            }
        }

        &:hover {
            .top-row {
                background-color: $color-secondary;
            }
        }
    }

    &__row {
        justify-content: center;
    }

    @media only screen and (max-width: 992px) {
        background-color: white;

        .promo-blocks__smallblock {
            .block-wrap {
                height: 100%;
            }

            .block-content {
                padding: pxToRem(15px);

                h3 {
                    font-size: pxToRem(14px);
                    font-weight: 900;
                    margin-bottom: pxToRem(10px);
                }

                p {
                    font-size: pxToRem(12px);
                    line-height: pxToRem(16px);
                }
            }
        }

        .promo-blocks__bigblock {
            .content-wrap {
                .top-row {
                    padding: pxToRem(35px);

                    .logo-image {
                        img {
                            max-width: pxToRem(185px);
                        }
                    }

                    .info-image {
                        display: block;
                        max-width: pxToRem(255px);
                        right: pxToRem(20px);
                    }
                }

                .bottom-row {
                    .content-wrap {
                        max-width: pxToRem(180px);
                    }
                }

                h2 {
                    font-size: pxToRem(21px);
                    font-weight: 900;
                    margin-bottom: pxToRem(10px);
                }

                p {
                    font-size: pxToRem(12px);
                    line-height: pxToRem(16px);
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .promo-blocks__smallblock {
            img {
                &.img-fluid {
                    max-height: 150px;
                    object-fit: cover;
                }
            }
        }

        .promo-blocks__bigblock {
            margin-top: pxToRem(15px);

            .content-wrap {
                h2 {
                    font-size: pxToRem(18px);
                }

                .bottom-row {
                    padding: pxToRem(20px);
                }

                .top-row {
                    .info-image {
                        max-width: pxToRem(185px);
                    }
                }
            }
        }
    }
}
