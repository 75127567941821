.single-article-wrap {
    .container {
        padding-top: pxToRem(40px);

        .perex-wrap {
            padding: pxToRem(25px) 0;
        }

        .block {
            padding: pxToRem(12.5px) 0;
        }

        .article-content {
            .block-with-image {
                figure {
                    img {
                        border-radius: pxToRem(3px);
                        border: 1px solid $color-gray-lightest;
                    }

                    figcaption {
                        margin-top: pxToRem(15px);
                    }
                }
            }

            .article-bottom-row {
                .about-topic-block {
                    background-image: url('#{$relative-path-to-images}/img/bg-about-topic-block.jpg');
                    background-size: contain;
                    background-repeat: no-repeat;
                    padding: pxToRem(50px) pxToRem(65px) pxToRem(50px) pxToRem(50px);
                    min-height: pxToRem(505px);

                    h3 {
                        display: flex;
                        align-items: center;

                        &::before {
                            content: url('#{$relative-path-to-images}/img/ico-brush-blue-lighter.svg');
                            display: inline-block;
                            width: 100%;
                            max-width: pxToRem(45px);
                            margin-right: pxToRem(15px);
                            font-weight: normal;
                        }
                    }

                    h4 {
                        font-size: pxToRem(18px);
                        font-weight: 500;
                    }

                    @media only screen and (max-width: 1200px) {
                        background-color: #fafafa;
                        background-image: none;
                        border: 1px solid $color-blue-lighter;
                        padding: pxToRem(25px);
                        min-height: inherit !important;

                        ul {
                            margin-bottom: 0 !important;
                        }
                    }

                    @media only screen and (max-width: 768px) {
                        margin: pxToRem(20px) auto;
                        padding: pxToRem(15px);
                    }
                }

                .author-wrap {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        max-width: pxToRem(60px);
                        min-width: pxToRem(60px);
                        margin-right: pxToRem(15px);
                    }

                    .name {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
