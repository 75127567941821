.monthly-action {
    min-height: pxToRem(325px);
    background-color: $color-blue-darker2;
    padding-bottom: pxToRem(15px);

    .left-col-wrap {
        position: relative;
        display: flex;

        .flex-wrapper {
            z-index: 5;
            padding-top: pxToRem(35px);
            padding-left: pxToRem(30px);
            padding-right: pxToRem(30px);

            @media only screen and (max-width: 993px) {
                align-items: center;
                justify-content: center;
                width: 100%;
                padding-bottom: pxToRem(20px);
            }

            .icon {
                img {
                    min-width: pxToRem(15px);
                    height: auto;
                    width: pxToRem(15px);
                }

                margin-right: pxToRem(15px);
            }

            h2 {
                padding-top: 0;
                color: white;
                font-size: pxToRem(33px);
                line-height: pxToRem(28px);
                font-weight: bold;
                text-transform: uppercase;
                margin-bottom: pxToRem(10px);
            }

            p {
                font-size: pxToRem(20px);
                font-weight: 500;
                color: white;
            }
        }

        .absolute-link {
            position: absolute;
            left: 51.5%;
            top: 123%;

            a {
                width: pxToRem(95px);
                height: pxToRem(95px);
                border-radius: 99%;
                background-color: $color-secondary;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                text-transform: uppercase;
                text-decoration: underline;
                text-align: center;
                font-weight: bold;
                font-size: pxToRem(14px);
            }

            @media only screen and (max-width: 1200px) {
                left: 52.5%;
                top: 99%;
            }

            @media only screen and (max-width: 992px) {
                top: inherit;
                bottom: -190%;
                left: 35%;
            }

            @media only screen and (max-width: 768px) {
                position: relative;
                top: inherit;
                bottom: inherit;
                left: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 35px;
            }
        }

        .background {
            position: absolute;
            top: 0;
            left: 0;

            @media only screen and (max-width: 993px) {
                display: none !important;
            }
        }
    }
}
