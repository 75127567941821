.single-product {
    .image-col {
        position: relative;

        .tags-wrap {
            z-index: 100000;
        }

        .product-large-slider {
            .slick-slide {
                .pro-large-img {
                    display: flex !important;
                    justify-content: center;
                    align-items: center;
                    max-height: pxToRem(500px);
                    min-height: pxToRem(500px);
                    height: 100%;
                    width: 100%;
                    border: 1px solid $color-gray-lightest;
                    border-radius: 3px;

                    .img-fluid {
                        max-width: 98%;
                        max-height: pxToRem(480px);
                    }
                }
            }

            .slick-prev {
                left: 25px;
                z-index: 100;

                &::before {
                    color: $color-gray-lighter;
                }

                @media only screen and (max-width: 1200px) {
                    height: 100%;
                    width: 45%;
                    left: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding-left: 20px;
                }
            }

            .slick-next {
                right: 25px;
                z-index: 100;

                &::before {
                    color: $color-gray-lighter;
                }

                @media only screen and (max-width: 1200px) {
                    height: 100%;
                    width: 45%;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    padding-right: 20px;
                }
            }
        }

        .pro-nav {
            .slick-slide {
                .pro-nav-thumb {
                    cursor: pointer;

                    img {
                        max-height: pxToRem(120px);
                        min-height: pxToRem(120px);
                        max-width: pxToRem(160px);
                        min-width: pxToRem(160px);
                        height: 100%;
                        object-fit: contain;
                        display: block;
                        width: 100%;
                        border-radius: 3px;
                        border: 1px solid $color-gray-lightest3;

                        @media only screen and (max-width: 1200px) {
                            min-width: inherit !important;
                        }
                    }
                }
            }
        }
    }


    .calculator {
        background-color: $color-gray-product-price-wrap;
        border-radius: 3px;
        border: 1px solid $color-gray-lightest;
        font-size: pxToRem(14px);
        color: #000000;
        font-weight: 500;
        padding-top: pxToRem(21px);
        padding-bottom: pxToRem(21px);

        &__header {
            font-size: pxToRem(16px);
            font-weight: 500;
        }

        &__question {
            font-size: pxToRem(14px);
        }

        &__inputs {
            text-align: center;

            .calculator-equals {
                padding: 0 pxToRem(16px);
            }
        }

        input {
            background: white;
            color: $color-gray;
            width: pxToRem(120px);
            min-height: pxToRem(40px);
            text-align: center;
            font-size: pxToRem(16px);
            border: 2px solid $color-gray-lightest;

            &:focus {
                outline: none;
            }
        }
    }

    .price-wrap {
        background-color: $color-gray-product-price-wrap;
        border-radius: 3px;
        border: 1px solid $color-gray-lightest;

        @media only screen and (max-width: 992px) {
            padding-left: pxToRem(20px);
            padding-right: pxToRem(20px);
        }

        .product-availability {
            span {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }

            .icon {
                max-width: pxToRem(15px);
                min-width: pxToRem(15px);
                margin-right: pxToRem(10px);
            }

            a {
                &:hover {
                    color: $color-dark;
                }
            }
        }

        .price {
            display: flex;
            width: 100%;
            align-items: flex-end;
            justify-content: center;
            font-size: pxToRem(14px);
            color: $color-dark;
            font-weight: 500;

            @media only screen and (max-width: 992px) {
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 15px 0;

                span {
                    font-size: pxToRem(28px) !important;
                    margin: pxToRem(10px) 0;
                }
            }

            span {
                font-size: pxToRem(28px);
                font-weight: bold;
                line-height: pxToRem(28px);
                color: $color-secondary;

                @media only screen and (max-width: 1200px) {
                    font-size: pxToRem(20px);
                    font-weight: bold;
                    line-height: pxToRem(21px);
                }
            }

            @media only screen and (max-width: 600px) {
                display: flex;
                align-items: center;
                flex-direction: column;
            }
        }

        .add-to-cart-wrap {
            display: flex;
            flex-direction: row;
            justify-content: center;

            a {
                @media only screen and (max-width: 992px) {
                    font-size: pxToRem(13px);

                    .icon {
                        display: none;
                    }
                }
            }

            .quantity {
                margin-right: pxToRem(20px);
                display: flex;

                input {
                    background: white;
                    color: $color-gray;
                    min-width: pxToRem(70px);
                    border: none;
                    text-align: center;
                    font-weight: bold;
                    font-size: pxToRem(16px);
                    border-top: 1px solid $color-gray-lightest;
                    border-bottom: 1px solid $color-gray-lightest;

                    &:focus {
                        outline: none;
                    }
                }

                .quantity-value-buttons {
                    background: $color-gray-lightest2;
                    display: flex;
                    flex-direction: column;
                    min-width: pxToRem(28px);
                    align-items: center;
                    justify-content: center;
                    border: 1px solid $color-gray-lightest;

                    &.left {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }

                    &.right {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }

                    a {
                        color: $color-red-categories-hover;
                        display: flex;
                        text-align: center;
                        text-decoration: none;
                        justify-content: center;
                        font-weight: bold;
                        font-size: pxToRem(15px);
                        width: 100%;
                    }

                    &.minus {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }

                    &.plus {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }
            }
        }
    }

    .about-product-row {
        hr {
            background: #e8e4e4;
            opacity: 1;
        }

        .single-product-widget {
            padding: pxToRem(30px) pxToRem(45px);
            background-color: $color-blue-lightest;
            border: 1px solid $color-blue-light;
            border-radius: 3px;

            .title-wrap {
                display: flex;
                align-items: center;

                .icon {
                    max-width: pxToRem(20px);
                    min-width: pxToRem(20px);
                    margin-right: pxToRem(5px);
                }
            }

            &.bottom-wave {
                position: relative;
                padding: 0;
                background-color: transparent;
                border: none;

                .wrapper {
                    padding: pxToRem(30px) pxToRem(45px);
                    background-color: $color-blue-lightest;
                    border: 1px solid $color-blue-light;
                    border-bottom: none;
                }

                &::after {
                    position: relative;
                    content: '';
                    width: 100%;
                    height: auto;
                    background-image: url('#{$relative-path-to-images}/img/bg-product-aside-bottom.png');
                    background-size: contain;
                    min-height: 85px;
                    display: block;
                    background-repeat: no-repeat;

                    @media only screen and (max-width: 768px) {
                        min-height: 105px;
                    }
                }
            }
        }
    }

    &.product-cancelled {
        .image-col {
            position: relative;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;

                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    text-align: center;

                    h2 {
                        padding-top: pxToRem(100px);
                        margin-bottom: 0;
                        text-transform: uppercase;
                        font-size: pxToRem(33px);
                        color: $color-dark;
                        line-height: pxToRem(40px);

                        @media only screen and (max-width: 992px) {
                            font-size: pxToRem(20px);
                        }
                    }
                }

                .background {
                    img {
                        margin: auto;

                        @media only screen and (max-width: 992px) {
                            max-height: pxToRem(220px);
                        }

                        @media only screen and (max-width: 400px) {
                            max-width: pxToRem(320px);
                        }
                    }
                }
            }
        }

        .pro-large-img {
            img {
                max-height: pxToRem(500px);
                max-width: 98%;
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .list-wo-padding {
            font-size: pxToRem(14px);
        }

        .aside {
            .single-product-widget {
                padding: pxToRem(20px);

                .wrapper {
                    padding: pxToRem(20px) !important;
                }

                .content-wrap {
                    font-size: pxToRem(12px);
                }
            }
        }

        .about-product-row {
            h2 {
                margin-top: pxToRem(50px);
                padding-bottom: pxToRem(30px);
            }

            p {
                font-size: pxToRem(12px);
                @media only screen and (max-width: 992px) {
                    padding-left: 0;
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .list-wo-padding {
            margin-bottom: pxToRem(40px);
        }

        .show-more {
            margin-bottom: pxToRem(33px);
        }
    }

    @media only screen and (max-width: 767px) {
        .list-wo-padding {
            margin-top: pxToRem(25px);
        }
    }
}

@media only screen and (max-width: 992px) {
    .single-product {
        .description-col {
            display: flex;
            flex-direction: column;
        }

        .price-wrap {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: pxToRem(5px);
        }

        .about-product-row {
            margin-top: pxToRem(20px);
            padding-bottom: 0;
        }
    }
}
