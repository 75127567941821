.bg-blue-sky {
    background-color: $color-blue-sky;
}

.bg-white-full {
    background-color: white;
}

.bg-blue-lightest {
    background-color: $color-blue-lightest;
}

.bg-alert-primary {
    background-color: $color-alert-primary;
}

.bg-gray-hr {
    background-color: $color-gray-hr;
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.text-gray-darker {
    color: $color-gray-darker !important;
}

.text-secondary {
    color: $color-secondary !important;
}

.text-blue-normal {
    color: $color-blue-normal !important;
}

.text-dark {
    color: $color-dark;
}

.text-dark-hover {
    color: $color-dark;

    &:hover {
        color: $color-dark;
    }
}

.text-dark-lighter {
    color: $color-dark-lighter !important;
}

.text-blue-sky {
    color: $color-blue-sky;
}

.text-gray-categories-hover {
    color: $color-gray-categories-hover;
}

.text-blue-darker {
    color: $color-blue-darker !important;
}

.text-blue-darker2 {
    color: $color-blue-darker2 !important;
}

.text-blue-darker3 {
    color: $color-blue-darker3 !important;
}

.text-blue-dark {
    color: $color-blue-dark !important;
}

.text-brown-light {
    color: $color-brow-light !important;
}

.text-red-categories-hover {
    color: $color-red-categories-hover !important;
}

.text-green-lighter {
    color: $color-green-lighter !important;
}

.text-orange {
    color: $color-orange !important;
}

.bg-page-bg {
    background-color: $color-page-bg;
}

.bg-sky-blue-75 {
    background-color: rgba($color-blue-sky, 0.75);
}

.text-gray-darker {
    color: $color-gray-darker;
}

.bg-gray-darker {
    background-color: $color-gray-darker;
}

.mb-100 {
    margin-bottom: pxToRem(100px);
}

.mb-85 {
    margin-bottom: pxToRem(85px);
}

.mb-55 {
    margin-bottom: pxToRem(55px);
}

.mb-60 {
    margin-bottom: pxToRem(60px);
}

.my-100 {
    margin: pxToRem(100px) 0;
}

.my-40 {
    margin: pxToRem(40px) 0;
}

.my-65 {
    margin: pxToRem(65px) 0;
}

.my-25 {
    margin: pxToRem(25px) 0;
}

.pt-90 {
    margin-top: pxToRem(90px);
}

.pb-55 {
    padding-bottom: pxToRem(55px);
}

.pl-15 {
    padding-left: pxToRem(15px);
}

.mt-25 {
    margin-top: pxToRem(25px);
}

.mt-45 {
    margin-top: pxToRem(45px);
}

.mt-50 {
    margin-top: pxToRem(50px);
}

.mt-65 {
    margin-top: pxToRem(65px);
}

.mt-80 {
    margin-top: pxToRem(80px);
}

.mr-45 {
    margin-right: pxToRem(45px);
}

.mr-10 {
    margin-right: pxToRem(10px);
}

.mr-20 {
    margin-right: pxToRem(20px);
}

.p-40 {
    padding: pxToRem(40px);
}

.p-45 {
    padding: pxToRem(45px);
}

.p-10 {
    padding: pxToRem(10px);
}

.pt-200 {
    padding-top: pxToRem(200px);
}

.pt-40 {
    padding-top: pxToRem(40px);
}

.pt-85 {
    padding-top: pxToRem(85px);
}

.pb-100 {
    padding-bottom: pxToRem(100px);
}

.pb-175 {
    padding-bottom: pxToRem(175px);
}

.pb-40 {
    padding-bottom: pxToRem(40px);
}

.pb-35 {
    padding-bottom: pxToRem(35px);
}

.pb-30 {
    padding-bottom: pxToRem(30px);
}

.pb-25 {
    padding-bottom: pxToRem(25px) !important;
}

.pb-80 {
    padding-bottom: pxToRem(80px);
}

.pb-70 {
    padding-bottom: pxToRem(70px);
}

.mb-40 {
    margin-bottom: pxToRem(40px);
}

.mb-45 {
    margin-bottom: pxToRem(45px);
}

.mb-20 {
    margin-bottom: pxToRem(20px);
}

.mb-25 {
    margin-bottom: pxToRem(25px);
}

.mb-15 {
    margin-bottom: pxToRem(15px);
}

.mb-80 {
    margin-bottom: pxToRem(80px);
}

.mb-115 {
    margin-bottom: pxToRem(115px);
}

.ml-15 {
    margin-left: pxToRem(15px);
}

.px-95 {
    padding-left: pxToRem(95px);
    padding-right: pxToRem(95px);
}

.px-15 {
    padding-left: pxToRem(15px);
    padding-right: pxToRem(15px);
}

.px-70 {
    padding-left: pxToRem(70px);
    padding-right: pxToRem(70px);
}

.pt-450 {
    padding-top: pxToRem(450px) !important;
}

.px-45 {
    padding-left: pxToRem(45px);
    padding-right: pxToRem(45px);
}

.py-35 {
    padding-top: pxToRem(35px);
    padding-bottom: pxToRem(35px);
}

.px-35 {
    padding-left: pxToRem(35px);
    padding-right: pxToRem(35px);
}

.py-50 {
    padding-top: pxToRem(50px);
    padding-bottom: pxToRem(50px);
}

.pr-65 {
    padding-right: pxToRem(35px);
}

.py-65 {
    padding-top: pxToRem(65px);
    padding-bottom: pxToRem(65px);
}

.py-80 {
    padding-top: pxToRem(80px);
    padding-bottom: pxToRem(80px);
}

.py-90 {
    padding-top: pxToRem(90px);
    padding-bottom: pxToRem(90px);
}

.p-60 {
    padding: pxToRem(60px);
}

.pt-35 {
    padding-top: pxToRem(35px);
}

.pt-70 {
    padding-top: pxToRem(70px);
}

.pt-65 {
    padding-top: pxToRem(65px);
}

.mt-35 {
    margin-top: pxToRem(35px);
}

.mt-30 {
    margin-top: pxToRem(30px);
}

.mt-15 {
    margin-top: pxToRem(15px);
}

.mt-95 {
    margin-top: pxToRem(95px);
}

.mt-100 {
    margin-top: pxToRem(100px);
}

.mt-135 {
    margin-top: pxToRem(135px);
}

.mt-280 {
    margin-top: pxToRem(280px);
}

.mt-260 {
    margin-top: pxToRem(260px);

    @media only screen and (max-width: 992px) {
        margin-top: pxToRem(220px) !important;
    }

    @media only screen and (max-width: 500px) {
        margin-top: pxToRem(180px) !important;
    }
}

.mt-300 {
    margin-top: pxToRem(300px);

    @media only screen and (max-width: 992px) {
        margin-top: pxToRem(220px) !important;
    }

    @media only screen and (max-width: 500px) {
        margin-top: pxToRem(180px) !important;
    }
}

.max-w-350 {
    max-width: pxToRem(350px);
}

.mb-35 {
    margin-bottom: pxToRem(35px);
}

.mb-30 {
    margin-bottom: pxToRem(30px);
}

.mb-75 {
    margin-bottom: pxToRem(75px);
}

.min-h-550 {
    min-height: pxToRem(550px);
}

.min-h-450 {
    min-height: pxToRem(450px);
}

.max-w-30 {
    max-width: pxToRem(30px);
}

.px-35 {
    padding-left: pxToRem(35px);
    padding-right: pxToRem(35px);
}

.br-3 {
    border-radius: 3px;
}

.px-25 {
    padding-left: pxToRem(25px);
    padding-right: pxToRem(25px);
}

.px-60 {
    padding-left: pxToRem(60px);
    padding-right: pxToRem(60px);
}

.mx-35 {
    margin-left: pxToRem(35px);
    margin-right: pxToRem(35px);
}

.mx-15 {
    margin-left: pxToRem(15px);
    margin-right: pxToRem(15px);
}

.mt-70 {
    margin-top: pxToRem(70px);
}

.max-w-33 {
    max-width: pxToRem(33px);
}

.fs-33 {
    font-size: pxToRem(33px);

    @media only screen and (max-width: 993px) {
        font-size: pxToRem(25px);
    }
}

.border-b-0 {
    border-bottom: none !important;
}

.pt-md-200 {
    @media only screen and (max-width: 992px) {
        padding-top: pxToRem(220px) !important;
    }
}

.md-py-45 {
    @media only screen and (max-width: 992px) {
        padding-top: pxToRem(65px) !important;
        padding: pxToRem(45px) 0 !important;
        margin: 0 auto !important;
    }
}

.no-bg-lg {
    @media only screen and (max-width: 768px) {
        background-color: #fafafa !important;
    }
}

/* TODO: dočasné skrytí message ve vyhledávání */
#frm-searchForm-form-query_message
{
    display: none;
}

.hand {
    cursor: pointer;
}

.justify-content-center {
     justify-content: center;
 }

.format-image {
    max-height: 32px;
}
