.tags-wrap {
  position: absolute;
  top: 10px;
  left: 25px;
  display: flex;
  flex-direction: row;

  .tag {
    padding: 0.25rem 0.5rem;
    color: white;
    border-radius: 5px;
    margin-right: 0.5rem;
    font-size: pxToRem(11px);
    background: #c93030; //default bg in case its not set

    &--red {
      background-color: #c93030;
    }

    &--blue {
      background-color: #9b0000;
    }

    &--lightblue {
      background-color: #30c9ac;
    }

    &--orange {
      background-color: #ff6200;
    }
  }

  &--product-tags {
    left: 12px;
  }
}
