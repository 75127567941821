.big-categories {
    &__category {
        margin-top: pxToRem(15px);
        margin-bottom: pxToRem(15px);

        @media only screen and (max-width: 769px) {
            width: 100% !important;
        }

        a {
            text-decoration: none;
        }

        &__wrapper {
            display: flex;
            background: #fff;
            border-radius: pxToRem(3px);
            border: 1px solid #f0e7e7;
            height: 100%;
            flex-direction: column;
            box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1);
        }

        &__divider {
            width: 1px;
            height: auto;
            margin: pxToRem(5px);
            background: #f0e7e7;
        }

        &__categoryimage {
            display: flex;
            overflow: hidden;

            @media only screen and (max-width: 769px) {
                min-width: pxToRem(205px);
            }

            img {
                width: 100%;
                height: 100%;
                max-height: pxToRem(200px);
                min-height: pxToRem(200px);
                object-fit: cover;
                border-top-left-radius: pxToRem(3px);
                border-top-right-radius: pxToRem(3px);
                transition: 0.25s ease-in-out all;

                @media only screen and (max-width: 992px) {
                    max-height: pxToRem(175px);
                    min-height: pxToRem(175px);
                }

                @media only screen and (max-width: 769px) {
                    max-width: pxToRem(205px);
                    min-width: pxToRem(205px);
                    max-height: pxToRem(135px);
                    min-height: pxToRem(135px);
                    border-radius: 3px;
                }
            }
        }

        &__categorytitle {
            padding: pxToRem(10px);
            font-size: pxToRem(15px);
            font-weight: 500;
            background: white;
            display: flex;
            justify-content: space-between;
            color: $color-gray;
            display: flex;
            align-items: center;

            .icon-wrap {
                background: $color-secondary;
                min-height: 35px;
                min-width: 35px;
                border-radius: pxToRem(3px);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid white;

                .icon-image {
                    width: pxToRem(13px);
                    height: pxToRem(13px);
                    display: block;
                }
            }

            @media only screen and (max-width: 769px) {
                width: 100%;
                padding-left: pxToRem(15px);
                padding-right: pxToRem(25px);

                .title {
                    margin-right: auto;
                    margin-right: pxToRem(10px);
                    font-size: pxToRem(12px);
                }
            }
        }

        &:hover {
            .big-categories__category__categoryimage {
                img {
                    transform: scale(1.1);
                }
            }

            .big-categories__category__categorytitle {
                background-color: $color-gray-categories-hover;
                color: white;

                .icon-wrap {
                    background: $color-red-categories-hover;
                }
            }

            a {
                text-decoration: underline;
                text-decoration-color: white;
            }
        }

        @media only screen and (max-width: 769px) {
            &__wrapper {
                flex-direction: row;
                overflow: hidden;
            }
        }
    }
}
