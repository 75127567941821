.store-detail-col {
    position: relative;

    .tags-wrap {
        z-index: 100000;
    }

    .product-large-slider {
        .slick-slide {
            .pro-large-img {
                img {
                    max-height: pxToRem(500px);
                    min-height: pxToRem(500px);
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    width: 100%;
                    border: 1px solid $color-gray-lightest;
                    border-radius: 3px;
                }
            }
        }

        .slick-prev {
            left: 25px;
            z-index: 100;

            &::before {
                color: $color-gray-lighter;
            }
        }

        .slick-next {
            right: 25px;
            z-index: 100;

            &::before {
                color: $color-gray-lighter;
            }
        }

        .slick-prev,
        .slick-next {
            width: 22px;
            height: 22px;
        }
    }

    .pro-nav {
        .slick-slide {
            .pro-nav-thumb {
                cursor: pointer;

                img {
                    max-height: pxToRem(120px);
                    min-height: pxToRem(120px);
                    max-width: pxToRem(160px);
                    min-width: pxToRem(160px);
                    height: 100%;
                    object-fit: cover;
                    display: block;
                    width: 100%;
                    border-radius: 3px;
                    border: 1px solid $color-gray-lightest3;
                }
            }
        }
    }
}
