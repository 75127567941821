.advantages-wrap {
    .advantage-content {
        max-width: pxToRem(160px);
        margin: auto;
        height: 100%;
        border-top-left-radius: pxToRem(4px);
        border-top-right-radius: pxToRem(4px);
        border-bottom-left-radius: pxToRem(16px);
        border-bottom-right-radius: pxToRem(16px);
        box-shadow: 6px 6px 16px 0px rgba(0, 0, 0, 0.1);

        .icon-wrap {
            display: flex;
            flex-direction: column;
            position: relative;
            align-items: center;
            justify-content: center;

            .background {
                svg {
                    fill: $color-blue-advantage;
                    max-height: pxToRem(99px);
                    min-height: pxToRem(99px);
                    transition: fill 0.3s ease-in-out;
                    width: 100%;
                }
            }

            .icon {
                position: absolute;
                top: pxToRem(20px);
                left: 0;
                right: 0;
                margin: auto;
                max-width: pxToRem(39px);
                height: auto;
            }
        }

        .description {
            margin-top: pxToRem(18px);
            margin-bottom: pxToRem(25px);
            padding: 0 pxToRem(13px);
        }

        &:hover {
            .background {
                svg {
                    fill: $color-red-categories-hover;
                }
            }
        }
    }

    @media screen and (max-width: 992px) {
        .advantage-col {
            margin-bottom: pxToRem(35px);
        }

        .row {
            justify-content: center;
        }
    }
}
