.header {
    /*
    background-color: rgba(#e5e5e5, 0.45);
    background-image: url('#{$relative-path-to-images}/img/header-background.jpg');
    background-size: 100% 100%;
    background-position-y: 30px;
    background-repeat: no-repeat; */

    /*    @media only screen and (min-width: 2100px) {
        background-image: url('#{$relative-path-to-images}/img/top-banner-4K.png');
    } */

    position: relative;
    display: flex;
    flex-direction: column;

    .before-nav {
        z-index: 10;

        .top-bar {
            background: #484344;
            padding: 0.25rem 0;
            font-size: 12px;
            align-items: flex-end;
            min-height: 30px;
            justify-content: center;
            display: flex;
            flex-direction: column;

            ul {
                display: flex;
                padding: 0;
                margin: 0;
                list-style: none;
                justify-content: space-between;
                gap: 10px;
                flex-wrap: wrap;

                li {
                    color: white;

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        text-decoration: none;

                        .icon {
                            width: 15px;
                            max-width: 15px;
                            height: auto;
                            margin-right: pxToRem(5px);
                        }

                        &:hover {
                            span {
                                text-shadow: 0 0 0.65px white, 0 0 0.65px white;
                            }
                        }
                    }
                }
            }

            @media screen and (max-width: 767px) {
                ul {
                    li {
                        a {
                            .icon {
                                display: block;
                                width: 15px;
                                max-width: 15px;
                                height: auto;
                                margin-right: pxToRem(5px);
                            }

                            /* -pokud nechceme text- span {
                                display: none;
                            }*/
                        }
                    }
                }
            }

            @include media-breakpoint-up('md') {
                ul {
                    justify-content: flex-end;
                    gap: 30px;
                }
            }
        }

        .middle-bar {
            min-height: pxToRem(130px);
            height: pxToRem(130px);
            display: flex;
            align-items: center;
            justify-content: center;

            .search-widget {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: center;

                .input-group {
                    input {
                        font-weight: 300;
                        border-color: $color-gray-search-border;
                        min-width: 275px;

                        @media screen and (max-width: 1200px) {
                            min-width: inherit !important;
                        }
                    }

                    button {
                        border-color: $color-gray-search-border;
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;

                        img {
                            width: 22px;
                        }
                    }
                }
            }

            .cart-widget {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 100%;
                max-width: 95%;
                margin-left: auto;

                @media (min-width: 979px) {
                    &:hover {
                        .dropdown-menu {
                            display: block;
                        }
                    }
                }

                .dropdown-menu {
                    width: 100%;

                    &.show {
                        inset: auto !important;
                        margin: 0 !important;
                        transform: none !important;
                    }

                    .shopping-cart-header {
                        margin-bottom: pxToRem(10px);
                        text-align: center;
                        padding-bottom: pxToRem(5px);
                        border-bottom: 1px solid $color-gray-search-border;

                        p {
                            font-weight: bold;
                            font-size: pxToRem(15px);
                            margin-bottom: 0;
                        }
                    }

                    .shopping-cart-items {
                        padding-left: 0;
                        list-style: none;
                        padding: 5px;
                        margin-bottom: 0;

                        li {
                            .flex-wrap {
                                width: 100%;
                                justify-content: space-between;

                                .content {
                                    width: 95%;
                                    .name {
                                        font-size: pxToRem(13px);
                                        font-weight: bold;
                                        color: #625959;
                                    }

                                    .quantity {
                                        font-size: pxToRem(13px);
                                        color: #625959;

                                        .piece {
                                            margin-left: pxToRem(5px);
                                        }
                                    }
                                }

                                .remove-btn {
                                    display: flex;
                                    cursor: pointer;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        max-width: 10px;
                                        min-width: 10px;
                                        display: block;
                                        width: 100%;
                                        transition: all 0.3s ease-in-out;
                                        opacity: 0.55;
                                    }

                                    &:hover {
                                        img {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }

                        li:not(:last-child) {
                            margin-bottom: pxToRem(10px);
                        }
                    }

                    .bottom-row {
                        display: flex;
                        flex-direction: column;
                        border-top: 1px solid $color-gray-search-border;
                        margin: pxToRem(10px) pxToRem(5px);

                        .total-price {
                            padding: pxToRem(10px) 0;
                        }

                        p {
                            margin-bottom: 0;
                            font-size: pxToRem(14px);
                            text-align: center;
                        }

                        .btn {
                            margin-top: pxToRem(5px) !important;
                        }
                    }
                }

                .cart-widget-wrapper {
                    background: white;
                    max-height: pxToRem(35px);
                    height: pxToRem(35px);
                    margin: auto;
                    border: 1.5px solid $color-gray-search-border;
                    border-radius: 3px;
                    display: flex;
                    width: 100%;
                    padding: 0 pxToRem(15px);
                    padding-left: 0;
                    align-items: center;
                    justify-content: center;

                    .cart-icon-wrap {
                        position: relative;
                        min-width: pxToRem(30px);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            max-width: pxToRem(22px);
                            width: auto;
                            height: auto;
                        }

                        .items-count {
                            font-size: pxToRem(11px);
                            min-width: pxToRem(19px);
                            max-height: pxToRem(19px);
                            font-weight: bold;
                            border-radius: 99%;
                            position: absolute;
                            color: white;
                            padding: pxToRem(2px) pxToRem(3px);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            left: pxToRem(-3px);
                            bottom: pxToRem(-3px);
                            background-color: $color-secondary;
                            border: 1.5px solid $color-gray-search-border;
                        }
                    }

                    .cart-description {
                        display: flex;
                        margin-left: pxToRem(15px);
                        align-items: center;
                        color: $color-dark-lighter;
                        font-size: pxToRem(14px);

                        .description {
                            font-weight: bold;
                            text-transform: uppercase;
                            margin-right: pxToRem(10px);
                        }
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                height: pxToRem(105px);
                min-height: pxToRem(105px);
                margin: auto pxToRem(20px);

                .icons-wrap {
                    .btn {
                        padding-bottom: 0;
                    }

                    .navbar-toggler {
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .navbar-brand {
        max-width: 255px;
        width: 100%;
        display: block;
        height: auto;
        margin: 2rem 0;

        @media only screen and (max-width: 768px) {
            max-width: 190px;
        }
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
        z-index: 9 !important;

        ul {
            width: 100%;

            li {
                padding: 0;
                margin-right: 1px;
                flex: auto !important;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.3s all ease-in-out;

                @media only screen and (max-width: 768px) {
                    border-radius: 10px;
                    margin: 0.25rem auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                }

                a {
                    color: white !important;
                    text-transform: uppercase !important;
                    background-color: #f20001;
                    font-size: pxToRem(13px);
                    font-weight: 600;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    width: 100%;
                    text-align: center;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;

                    @media only screen and (max-width: 1250px) {
                        font-size: 12px;
                        padding-left: 0.25rem !important;
                        padding-right: 0.25rem !important;
                        height: 100%;
                    }

                    &:hover,
                    &.active {
                        background-color: $color-primary;
                    }
                }

                .dropdown-menu {
                    background: transparent;
                    border: none !important;
                    z-index: 1000000000 !important;
                    background-color: white;
                    padding-left: pxToRem(35px);
                    padding-right: pxToRem(35px);
                    padding-top: pxToRem(20px);
                    padding-bottom: pxToRem(20px);
                    border-top-left-radius: 0 !important;
                    border-top-right-radius: 0 !important;

                    @media only screen and (max-width: 500px) {
                        padding-left: pxToRem(15px);
                        padding-right: pxToRem(15px);
                    }

                    &.show {
                        top: 100% !important;
                    }

                    .col-megamenu {
                        margin: pxToRem(20px) auto;
                    }

                    a {
                        color: #000000 !important;
                        text-decoration: underline;
                        font-size: pxToRem(12px);
                        font-weight: 300;
                        text-transform: inherit !important;
                        background: transparent !important;

                        @media only screen and (max-width: 1250px) {
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                        }

                        @media only screen and (max-width: 768px) {
                            padding: 0 !important;
                            display: inline-block !important;
                            width: inherit !important;
                            font-size: pxToRem(12px) !important;

                            &::after {
                                display: none !important;
                            }
                        }
                    }

                    .image-wrapper {
                        width: 100%;
                        display: flex;
                        height: auto;
                        overflow: hidden;

                        img {
                            align-self: center;
                            width: 100%;
                            height: auto;
                            display: block;
                            margin: auto;
                        }
                    }

                    h6 {
                        font-size: pxToRem(14px);
                        font-weight: 500;
                        margin-bottom: pxToRem(12px);
                    }

                    @media all and (max-width: 993px) {
                        .image-col {
                            padding-right: 0;
                        }

                        .links-col {
                            padding-left: 0;
                        }
                    }

                    @media all and (max-width: 768px) {
                        border-top-left-radius: 0.25rem !important;
                        border-top-right-radius: 0.25rem !important;

                        .megamenu-col-wrap {
                            border-bottom: 1px solid #f0e7e7;
                        }

                        .megamenu-col-wrap:last-child() {
                            border-bottom: transparent;
                        }
                    }
                }
            }
        }

        .dropdown-toggle {
            white-space: normal !important;

            &::after {
                display: none;
            }
        }
    }

    .mobile-nav {
        position: relative;
        .middle-bar {
            height: inherit !important;
        }

        .cart-icon-wrap {
            position: relative;
            min-width: pxToRem(30px);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-width: pxToRem(22px);
                width: auto;
                height: auto;
            }

            .items-count {
                font-size: pxToRem(11px);
                min-width: pxToRem(19px);
                max-height: pxToRem(19px);
                font-weight: bold;
                border-radius: 99%;
                position: absolute;
                color: white;
                padding: pxToRem(2px) pxToRem(3px);
                display: flex;
                align-items: center;
                justify-content: center;
                left: pxToRem(-3px);
                bottom: pxToRem(-3px);
                background-color: $color-secondary;
                border: 1.5px solid $color-gray-search-border;
            }
        }

        .icons-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 100%;
            justify-content: flex-end;

            .search-widget {
                .btn {
                    background: inherit;

                    img {
                        width: 100%;
                        min-width: 25px;
                        max-width: 25px;
                    }
                }

                .search-form {
                    position: absolute;
                    top: 85px;
                    left: 5%;
                    right: 5%;
                    z-index: 5;
                    background-color: #484344;
                    border-radius: 3px;
                    border: 1px solid #484344;
                    padding: 16px 32px 16px 32px;

                    display: none;
                    &.visible {
                        display: block;
                    }

                    .form-input-message {
                        order: 3;
                    }
                }
            }

            .cart-widget {
                .cart-widget-wrapper {
                    background: inherit;
                    border: none;
                }
            }

            .icon {
                .navbar-light {
                    button {
                        border: none;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .navbar {
            ul {
                li {
                    a {
                        font-size: pxToRem(11px);
                        padding-top: pxToRem(10px);
                        padding-bottom: pxToRem(10px);
                        padding-left: pxToRem(15px);
                        padding-right: pxToRem(15px);
                        background: transparent !important;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .collapsing {
            -webkit-transition: none;
            transition: none;
            display: none;
        }

        .navbar-collapse {
            padding-top: 30px;
            &.show {
                position: fixed;
                display: flex;
                align-items: center;
                flex-direction: column;
                height: 100%;
                width: 100%;
                background-color: #ee0d19;
                z-index: 1000000;
                left: 0;
                top: 0;
                background-image: url('#{$relative-path-to-images}/img/mobile-menu-bg.png');
                background-repeat: no-repeat;
                background-size: contain;
                overflow-y: auto;

                @media only screen and (max-width: 675px) {
                    background-image: url('#{$relative-path-to-images}/img/bg-mobile-menu-675.png');
                }

                @media only screen and (max-width: 480px) {
                    background-image: url('#{$relative-path-to-images}/img/bg-mobile-menu-480.png');
                }

                @media only screen and (max-width: 400px) {
                    background-image: url('#{$relative-path-to-images}/img/bg-mobile-menu-400.png');
                }

                .close-btn {
                    width: 100%;
                    background-color: #ee0d19;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    button {
                        appearance: none !important;
                        -webkit-appearance: none !important;
                        color: white;
                        padding: 0;
                        margin: 0;
                        border: none;
                        width: 100%;
                        height: 55px;
                        padding: 0 pxToRem(20px);

                        span {
                            font-size: pxToRem(14px);
                            font-weight: 500;
                            display: flex;
                            align-items: center;
                            line-height: pxToRem(15px);
                            justify-content: flex-end;
                        }

                        img {
                            max-width: 15px;
                            min-width: 15px;
                            height: auto;
                            margin-left: pxToRem(10px);
                        }
                    }
                }

                .navbar-nav {
                    padding-top: pxToRem(75px);
                    padding-left: pxToRem(50px);
                    padding-right: pxToRem(50px);

                    @media only screen and (max-width: 480px) {
                        padding-left: pxToRem(10px);
                        padding-right: pxToRem(10px);
                    }

                    li {
                        border-radius: 0;
                        background-color: transparent;

                        a {
                            font-size: pxToRem(15px);
                            justify-content: space-between;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            text-transform: uppercase;

                            &::after {
                                display: block;
                                width: 15px;
                                height: 8px;
                                background-image: url('#{$relative-path-to-images}/img/icon-chevron-right-white.svg');
                                content: '';
                                background-repeat: no-repeat;
                                margin-left: auto;
                            }
                        }

                        &.dropdown {
                            a {
                                &::after {
                                    background-image: inherit;
                                    display: inline-block;
                                    margin-left: 0;
                                    margin-right: 7px;
                                    vertical-align: 0.255em;
                                    content: '';
                                    width: auto;
                                    border-top: 5px solid;
                                    border-right: 5px solid transparent;
                                    border-bottom: 0;
                                    border-left: 5px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .header-background {
        position: absolute;
        top: pxToRem(30px);
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        z-index: -1;
        background-image: url('#{$relative-path-to-images}/img/header_background.png');

        @media all and (min-width: 1950px) {
            background-size: cover;
        }

        @media all and (max-width: 768px) {
            background-image: url('#{$relative-path-to-images}/img/header_background_phone.jpg');
            background-size: cover;
            top: 0;
        }
    }

    .header-second-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        z-index: -3;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.navbar .megamenu {
    padding: 1rem;
}

@media all and (min-width: 768px) {
    .navbar .has-megamenu {
        position: static !important;
    }
    .navbar .megamenu {
        left: 0;
        right: 0;
        width: 100%;
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .navbar.fixed-top .navbar-collapse,
    .navbar.sticky-top .navbar-collapse {
        overflow-y: auto;
        max-height: 90vh;
        margin-top: 10px;
    }
}

.screen-darken {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    pointer-events: none;
    z-index: 7;
    opacity: 1;
    visibility: visible;
    position: absolute;
    min-height: 300%;
}

.navbar {
    position: relative;
    z-index: 15;
}
