footer {
    .newsletter-section {
        transition: 0.3s ease-in-out;

        .image-wrap {
            margin-top: pxToRem(-35px);
            transition: transform 0.3s ease-in-out;

            img {
                transition: transform 0.3s ease-in-out;
            }
        }

        .newsletter-widget {
            .btn {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:hover {
            background: $color-red-darkest;

            .image-wrap {
                transform: scale(1.09);

                img {
                    transform: rotate(-3deg);
                }
            }
        }
    }

    .footer-content {
        background-image: url('#{$relative-path-to-images}/img/footer_background.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media only screen and (max-width: 500px) {
            background-image: url('#{$relative-path-to-images}/img/footer_background_mobile.png');
            background-size: contain;
            background-position: top center;
            background-color: #f5f0f0;
        }

        .footer-firstcol {
            color: #8c7d7d;
            font-size: pxToRem(12px);

            @media only screen and (max-width: 768px) {
                margin-bottom: pxToRem(95px);
            }

            .footer-logo {
                margin-bottom: pxToRem(25px);

                @media only screen and (max-width: 768px) {
                    max-width: pxToRem(180px);
                }
            }

            p {
                color: $color-gray-lighter;
            }
        }

        .footer-menucol {
            h4 {
                margin-bottom: pxToRem(25px);
            }

            ul {
                li {
                    font-size: pxToRem(12px);
                    color: $color-gray;

                    a {
                        font-size: pxToRem(12px);
                        color: $color-gray;
                        text-decoration: none;
                        font-weight: 300;

                        &:hover {
                            text-decoration: underline;
                            color: $color-red-darker;
                        }
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                margin-bottom: pxToRem(75px);
            }
        }

        .social-icons {
            display: flex;
            align-items: center;
            margin-top: 2.5rem;

            .social {
                min-width: 25px;
                height: auto;
                margin-right: pxToRem(10px);

                svg {
                    fill: #f4a1a1;
                }

                &:hover {
                    svg {
                        fill: #e85555;
                    }
                }
            }
        }
    }

    .bottom-bar {
        min-height: 85px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;

        .icons-wrap {
            img {
                opacity: 0.75;
                transition: 0.25s ease-in-out all;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .makers-wrap {
        .maker {
            display: flex;
            flex: 1;
            flex-direction: row;
            font-size: pxToRem(11.5px);
            align-items: center;
            justify-content: center;

            p {
                font-size: pxToRem(11.5px);
                margin-bottom: 0;
                line-height: pxToRem(11.5px);
            }

            img {
                margin: 0;
                align-self: center;
                margin-left: pxToRem(10px);
            }

            a {
                color: $color-dark;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        .footer-menucol {
            ul {
                li {
                    a {
                        display: flex;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 992px) {
        .placeholder {
            display: none !important;
        }

        .makers-wrap {
            margin: pxToRem(15px) auto;
        }

        .bottom-bar {
            height: inherit !important;
            padding: pxToRem(15px) 0;
        }

        .copyright {
            justify-content: center !important;

            span {
                display: block;
                text-align: center;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .container {
            padding-bottom: 0;
        }

        .copyright {
            justify-content: center !important;
            margin-bottom: pxToRem(25px);
        }
    }

    @media only screen and (max-width: 500px) {
        .makers-wrap {
            flex-direction: column !important;

            .maker:first-child {
                margin-bottom: pxToRem(15px);
            }
        }
    }
}
