.faq-accordion {
    .accordion-item {
        border: none;
        border-radius: 0;
        border-top: 1px solid #f0e7e7;
    }

    .accordion-item:first-of-type .accordion-button {
        border-radius: 0;
    }

    .accordion-header {
        color: $color-blue-darker2;
        padding-top: 0;
        font-weight: 300;

        button {
            color: $color-blue-darker2;
            font-weight: bolder;
            padding-top: pxToRem(25px);
            padding-bottom: pxToRem(25px);
            font-size: pxToRem(18px);

            @media only screen and (max-width: 768px) {
                font-size: pxToRem(13px);
            }
        }
    }

    .accordion-body {
        background-color: #f4fafa;
        padding: pxToRem(30px);

        h4 {
            font-weight: bold;
            font-size: pxToRem(16px);
            margin-bottom: pxToRem(25px);
        }

        @media only screen and (max-width: 768px) {
            .title-wrap-padded {
                margin-top: pxToRem(20px);
            }
        }
    }

    .accordion-button {
        &::after {
            background-image: url('#{$relative-path-to-images}/img/chevron-down-blue-darker.svg');
        }

        &:focus {
            border-color: inherit !important;
            box-shadow: none !important;
        }
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
    }
}
